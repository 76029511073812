@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Listing
--------------------------------------------------*/
.listing {
    display: flex;
    flex-direction: row;
    margin: 0 -15px;
    flex-wrap: wrap;
       
    //slick
    @media (max-width: 470px) {
        display: block;
        margin: 0;
        padding-bottom: 50px;
    } 

    .box {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;   
        margin-bottom: 50px;

        //slick
        @media (max-width: 470px) {
            padding: 0;
        } 

        & > a {   
            //flex: 0 0 30%;
            text-transform: uppercase;
        }

        .no-link {
          pointer-events: none;
        }

        .btn {
            margin-bottom: 0;
        }

        .eyelet {
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 5px;

            @include sm {
                font-size: 14px;
            }
        }

        .wrap {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            height: auto;
            justify-content: space-between;
        }

        ul li a,
        .text a {
            font-weight: 700;
            text-decoration: underline;
                
                &:hover {
                text-decoration: none;
                color: $dark-grey;
            }
        }

        .img {
            margin-right: 30px;
                
            @include sm {
                margin-right: 0;
            }

            &.img-cover {
                margin-bottom: 0;
                flex: none;
                padding-bottom: calc(100% - 30px);

                @include sm {
                    padding-bottom: 60%;
                    margin-bottom: 30px;
                }

                @include xs {
                    padding-bottom: 46%;
                }

                @include xxs {
                    padding-bottom: 75%;
                }

                //slick
                    @media (max-width: 470px) {
                    padding-bottom: 60%;
                }
            }
        }

        &.news-medic {
           
          .img {
            background: $pink;
          }
        }

        &.tit-over {
         
          .img {
              @include xs {
                  display: block;
              }
          }

          .wrap {
            padding-top: 30px;
          }
        }
    }

    //listing-grid
    &.listing-grid {

        .box {
            flex: 0 0 25%;
            max-width: 25%;
            //display: block;
            display: flex;
            flex-direction: column;

            @include sm {
                flex: 0 0 50%;
                max-width: 50%;
                margin-bottom: 30px;
            }

            @include xs {
                margin-bottom: 60px;
            }
            @include xxs {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 50px;
            }

            a .img {
                padding-bottom: 70%;
                margin-right: 0;

                @include sm {
                    margin-bottom: 0;
                }

                @include xxs {
                    padding-bottom: 60%;
                }
            }

            &.news .wrap {
                margin-top: 30px;

                .eyelet {
                    font-size: 12px;
                }
            }
        }
    }

    //listing-medic
    &.listing-medic {

      .box a .img {
        padding-bottom: 100%;

        svg {
          top: 10px;
        }
      }

      .no-thumb {
          padding-bottom: 100%;
      }
    }
}


.no-thumb {
  background: $pink; 
  width: 100%; 
  padding-bottom: 70%;
  position: relative;
  z-index: 1;

  .fa {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
    color: #d6d2cf;
    font-size: 35px;
  }
}