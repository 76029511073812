@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Slider
--------------------------------------------------*/

.slick-slider {
    
    button {

        &.slick-prev {
            left: 10px;
            z-index: 3;

            &:before {
                font-family: 'fontawesome';
                content: '\f104';
                color: $red;
                font-size: 30px;
                font-weight: 700;
                line-height: 0;
            }
        }

        &.slick-next {
            right: 10px;
            z-index: 3;
            
            &:before {
                font-family: 'fontawesome';
                content: '\f105';
                color: $red;
                font-size: 30px;
                font-weight: 700;
                line-height: 0;
            }
        }
    }

    .slick-dots {
        bottom: 0;

        li button:before {
            font-size: 10px;
            color: $red;
        }
    }
}


//main-slider
.main-slider {
    overflow: hidden;

    &.slick-dotted {
        margin-bottom: 0;
    }

    .item {
        position: relative;
        z-index: 1; 
        width: 100vw;
        height: calc(100vh - #{$search-panel-height} - #{$header-height}); 
        
        @include sm {
            height: auto;
        }

        @include notebook {
            height: calc(100vh - #{$search-panel-height-sm} - #{$header-height});
        }

        a {
            @include sm {
                display: flex;
                flex-direction: column;
            }

            //phone landscape
            @media screen and (orientation: landscape) 
            and (max-device-width: 768px) {
                display: block;
            }


						
        }

        a:hover {
            text-decoration: none;
        }

        .box {
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 10%;
            transform: translateY(-50%);
            max-width: 40%;

            @include sm {
                position: static;
                top: auto;
                left: auto;
                transform: none;
                order: 2;
                padding: 50px 5% 30px 5%;
                max-width: 70%;
            }

            //tablet landscape
            @media screen and (orientation:landscape) 
            and (max-device-width: 1024px) {
               max-width: 43%;
            }

            @include xs {
                max-width: 100%;
            }

            //phone landscape
            @media screen and (orientation: landscape) 
            and (max-device-width: 768px) {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                max-width: 70%;
            }

            .h2 {
                color: $white; 
                font-size: 50px;
                margin-bottom: 10px;

                @include sm {
                    color: $dark-grey; 
                    font-size: 40px;
                }

                //tablet landscape
                @media screen and (orientation:landscape) 
                and (max-device-width: 1024px) {
                   font-size: 34px;
                }

                @include xs {
                    font-size: 27px;
                }

                //phone landscape
                @media screen and (orientation: landscape) 
                and (max-device-width: 768px) {
                    color: $white;
                    font-size: 22px;
                }
            }

            .text {
                color: $white;
                font-size: 22px;
                margin-bottom: 20px;

                @include sm {
                    color: $dark-grey; 
                    font-size: 18px;
                }

                //tablet landscape
                @media screen and (orientation:landscape) 
                and (max-device-width: 1024px) {
                   font-size: 19px;
                }

                @include xs {
                    font-size: 16px;
                }

                //phone landscape
                @media screen and (orientation: landscape) 
                and (max-device-width: 768px) {
                    color: $white;
                    font-size: 14px;
                }
            }

            .btn {
                margin-bottom: 0;
            }
        }

        .img {
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            
            @include sm {
                position: static;
                order: 1;
                 height: 50vh;
            }

            //tablet landscape
            @media screen and (orientation:landscape) 
            and (max-device-width: 1024px) {
               height: 71vh;
            }

            svg {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                @include sm {
                    position: static;
                }

                &.original {
                    display: block;
                }

                &.mobile {
                    display: none;
                }

                @include xs {
                    &.original {
                        display: none;
                    }

                    &.mobile {
                        display: block;
                    }
                }

                //phone landscape
                @media screen and (orientation:landscape) 
                and (max-device-width: 768px) {
                    &.original {
                        display: block;
                    }

                    &.mobile {
                        display: none;
                    }
                }

            }
        }

        //da tablet in su
        @media (min-width: 990px) {
            a {
                .img svg {
                    transition: transform 1s $smooth;
                } 

                &:hover {

                    .img svg {
                        transform: scale(1.02);
                    }
                }
            }

            //ie11 hack
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {  
                a .img svg {
                    transition: none;
                }

                a:hover .img svg {
                    transform: none;
                }
            }
        }
    }

    &.slick-dotted {
       
        .slick-dots {
            background: $white;
            
            @include sm {
                bottom: auto;
                top: 51vh;
            }

             //tablet landscape
            @media screen and (orientation:landscape) 
            and (max-device-width: 1024px) {
               top: 70vh;
            }

            //phone landscape
            @media screen and (orientation: landscape) 
            and (max-device-width: 768px) {
                top: auto;
                bottom: 0;
            }
        }
    }

    /*&.slick-dotted {
           
        .slick-dots {
            z-index: 1;
            bottom: -8px;
            pointer-events: none;

            li {
                height: auto;
                position: relative;
                z-index: 1;
                margin: 0;
                padding: 50px 0 0 0;
                transform: translateY(calc(100% - 58px));
                transition: transform .4s $smooth;
                pointer-events: all;

                @include sm {
                    padding: 30px 0 0 0;
                    transform: translateY(calc(100% - 38px));
                }

                @include xs {
                    padding: 20px 0 0 0;
                    transform: translateY(calc(100% - 28px));
                } 
                    
                //da tablet in su
                @media (min-width: 990px) {
                    &:hover {
                        transform: translateY(0);
                    }
                }

                .dot {
                    border-top: 8px solid $light-grey;
                    transition: border-top .4s $smooth;

                    .img {
                        position: relative;
                        z-index: 1;
                        display: block;
                        height: 0;
                        padding-bottom: 25%;
                            
                        svg {
                            position: absolute;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                //ie11 hack
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
                    .dot .img {
                        display: none
                    }
                }

                &.slick-active {

                    .dot {
                        border-top: 8px solid $red;
                    }
                }
            }
        }
    }

    &.small-dots {

        .slick-dots li .dot .img {
            padding-bottom: 16%;
        }
    }*/
}

//carousel
.carousel {
    margin-bottom: 37px;
    padding: 0 30px;

    .item {
        text-align: center;

        a {
            
           .icon {
               
                i {
                    color: $red;
                    display: block;
                    font-size: 50px;
                    padding-bottom: 10px;

                    @include xl {
                       font-size: 40px;
                    }
                }
           }
        }
    }
}

.slider--wrapped {
    margin-bottom: 100px;
    position: relative;

    .main-slider {

        .item {
            height: calc(100vh - 300px - 130px);

            @include sm {
                height: auto;
            }
        }
    }

    .slick-slider {
        position: static;
    }

    .slick-slider button.slick-prev {
        left: 26px;
    }

    .slick-slider button.slick-next {
        right: 26px;
    }

    .slick-dots {
        left: 0;
    }
}