@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Nav
--------------------------------------------------*/

//nav-main
.nav-main {
    float: right;
    margin-top: 50px;
    text-align: right;
    transition: margin-top .2s $smooth;

    @include sm {
        display: none;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0 -15px;

        @include xl {
            margin: 0 -8px;
        }

        li {
            display: inline-block;
            text-transform: uppercase;
                
            a {
                color: $dark-grey;
                font-weight: 700;
                padding: 0 15px;

                @include xl {
                    padding: 0 8px;
                }

                @include md {
                     font-size: 14px;
                }

                &:hover {
                    color: $red;
                    text-decoration: none;
                }
            }
        }

        .active {
            position: relative;
            z-index: 1;
            color: $red;

            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                left: calc(50% - 8px);
                bottom: -16px;
                width: 0; 
                height: 0; 
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid $red;
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                bottom: -10px;
                left: 15px; 
                width: calc(100% - 30px);
                height: 4px;
                background: $red;

                @include xl {
                    left: 8px;
                    width: calc(100% - 16px);
                }
            }
        }

        .sub {
            position: absolute;
            z-index: 1;
            top: 120px;
            left: 0;
            width: 100%;
            margin: 0;
            background: $white;
            transition: all .3s $smooth;
            padding: 60px 5%;
            opacity: 0;
            pointer-events: none;
            box-shadow: 0 17px 30px -10px rgba(0, 0, 0, .2);

            &.subnav-open { 
                opacity: 1;
                pointer-events: all;
            }

            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                float: left;

                li {
                    flex: 0 0 30%;
                    max-width: 30%;
                    padding: 0;
                    display: block;
                    text-transform: none;
                    text-align: left;
                    border-bottom: 1px solid $light-grey;
                    margin-bottom: 30px;
                    margin-right: 20px;

                    a {
                        display: block;
                        padding: 0;
                        color: $red;

                        &:hover {
                            @include alphatxt($dark-grey, .6)
                        }
                    }
                }
            }

            .img {
                float: right;
            }
        }
    }
}


//nav-service
.nav-service {
    position: absolute;
    top: 4px;
    right: 5%;
    text-align: right;
    margin: 0 -10px;
    transition: transform .3s $smooth;

    @include md {
         top: 2px;
    }

    @include sm {
        display: none;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        float: left;
        
        &:after {
            @include clearfix;
        }
          
        li {
            display: inline-block;

            a {
                color: $dark-grey;
                font-size: 14px;
                padding: 8px 12px;
                text-decoration: none;

                @include md {
                    font-size: 12px;
                    padding: 8px 10px;
                }

                &:hover {
                    color: $red;
                    background: $pink;
                }
            }
        }

        &.lang-toggle {
            position: relative;
            z-index: 1;
            overflow: hidden;
            height: 24px;
            margin-left: 30px;
            transition: height .2s $smooth;

            @include md {
                margin-left: 15px;
            }

           span {
                position: absolute;
                z-index: 1;
                height: 14px;
                width: 14px;
                top: 5px;
                right: 0;
                overflow: hidden;

                &:before,
                &:after {
                    font-family: 'fontawesome';
                    position: absolute;
                    z-index: 1;
                    right: 0;
                    color: $dark-grey;
                    transition: top .2s $smooth;
                }

                &:before {
                    content: '\f106';
                    top: 4px;
                }

                &:after {
                    content: '\f107';
                    top: -5px;
                }
           }

            li {
                display: block;
                height: 21px;

                a {
                    padding: 8px 16px 8px 12px;

                    &:hover {
                        color: $red;
                        background: transparent;
                    }
                }
            }

            &.lang-open {
                height: 43px;

                span {
                     &:before {
                         top: -5px;
                     }

                     &:after {
                         top: -15px;
                     }
                }
            }
        }
    }
}

//nav-toggle
.nav-toggle {
    display: none;
    position: fixed;
    z-index: 1;
    top: 18px;
    right: 18px;
    width: 36px;
    height: 36px;
    
    button {
        border: 0;
        background: none;
        cursor: pointer;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 0;

        &:after,
        &:before,
        span {
            content: '';
            position: absolute;
            z-index: 1;
            width: 24px;
            height: 2px;
            background: $blue;
            left: 6px;
        }

        &:after {
            top: 12px;
            transition: all .2s $smooth .2s;
        }

        span {
            top: 18px;
            transition: all .2s $smooth ;

            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                width: 2px;
                height: 24px;
                background: $blue;
                left: 11px;
                top: -11px;
                transform: scaleY(0);
                transition: all .2s $smooth ;
                transform-origin: center;
            }
        }

        &:before {
            top: 24px;
            transition: all .2s $smooth .2s;
        }
    }

    @include sm {
        display: block;
    }
}

//nav-mobile
.nav-mobile {
    display: none;
    position: absolute;
    z-index: 3;
    top: 75px;
    left: 0;
    width: calc(100vw + 10vw);
    height: calc(100vh - 73px - #{$sticky-height-compact});
    background: $white;
    transition: transform .6s $smooth;
    transform: translateX(0);
    overflow-x: hidden;
    overflow-y: auto;

    .img {
        display: none;
    }
    
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .nav-main-mobile {
        padding: 20px calc(20px + 10vw) 20px 20px;
       
        ul {

            li {
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 700;
            
                a {
                    color: $dark-grey;
                    display: block;
                    padding: 10px 0;
                    text-decoration: none;

                    &.parent {
                        position: relative;
                        z-index: 1;

                        &:after {
                            position: absolute;
                            top: 6px;
                            right: 0;
                            z-index: 1;
                            font-family: 'fontawesome';
                            content: '\f105';
                            font-weight: 700;
                            font-size: 21px;
                            color: $red;
                        }
                    }

                    &.active {
                        color: $red;
                    }
                }

                .sub {
                    position: absolute;
                    z-index: 4;
                    top: 0;
                    left: 10vw;
                    width: calc(100vw + 10vw);
                    height: calc(100vh - 75px);
                    overflow-x: auto;
                    background: #f6f5f5;
                    transform: translateX(100vw);
                    transition: transform .6s $smooth;

                    &.subnav-open {
                         transform: translateX(0);
                    }

                    ul {
                        padding: 0 20px 0 40px;
                        
                        li {
                            text-transform: none;

                            a {
                                color: $red;
                                font-size: 14px;

                                &:hover {
                                    @include alphatxt($dark-grey, .6)
                                }
                            }
                        }
                    }

                    //back button
                    .nav-sub-back {
                        position: absolute;
                        z-index: 5;
                        top: 0;
                        left: 0;
                        width: calc(100vh - 75px);
                        transform-origin: left top;
                        transform: rotate(-90deg) translateX(-100%);
                        padding: 2px 0;
                        text-align: center;
                        font-size: 14px;
                        background: $red;
                        color: $white;
                        box-shadow: calc(-100vh + 75px) 0 0px 0px rgba(180,17,26,1);

                        &:after {
                            position: absolute;
                            top: -5px;
                            right: 10px;
                            z-index: 1;
                            font-family: 'fontawesome';
                            content: '\f106';
                            font-weight: 700;
                            font-size: 21px;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .nav-service-mobile {
        padding-bottom: 20px;

        ul {
            position: relative;
            z-index: 1;
            left: 0;

            li {
                font-size: 14px;
                
                a {
                    color: $dark-grey;
                    display: inline-block;
                    padding: 4px 10px 4px 25px;
                    text-decoration: none;
                }

                &.active {
                    
                    a {
                        font-weight: 700;
                        background: $pink;
                    }
                }
            }

            &.lang-toggle {
               
            }
        }
    }
}

//subnav-panel-open
body.subnav-panel-open {

    .nav-mobile {
        transform: translateX(-10vw);
        overflow: hidden;
    }
}

//nav-mobile-open
body.nav-mobile-open {
    
    .nav-mobile {
        display: block;
    }

    .nav-toggle {

        button {

            &:before {
                transform: scaleX(0);
                transition: all .2s $smooth;
            }

            span {
                transform: rotate(45deg);
                transition: transform .2s $smooth .2s;

                &:after {
                     transform: scaleY(1);
                     transition: transform .2s $smooth .3s;
                }
            }

            &:after {
                transform: scaleX(0);
                transition: all .2s $smooth;
            }
        }
    }
}