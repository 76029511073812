@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Sticky Info
--------------------------------------------------*/

//floating chat
.floating-chat {
    position: absolute;
    z-index: 1;
    bottom: 59px;
    right: 0;
    height: 560px;
    width: 500px;
    box-shadow: 0 0 18px 0px rgba(0, 0, 0, 0.19);
    transform: translateX(100%);
    transition: transform .4s $smooth;

    @include xs {
        position: fixed;
        width: 100vw;
        height: calc(100vh - 75px); //header height
        top: 75px; //header height
        bottom: auto;
    }

    iframe {
        width: 100%;
        height: 100%;
        background: white;

        .chat-column {
            max-width: 100%;
        }
    }
}

.floating-chat-open,
.floating-chat-close {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0px;
    width: 40px;
    height: 40px;
    background: $red;
    pointer-events: all;
    cursor: pointer;

    @include xs {
        top: auto;
        bottom: -1px;
        width: 100vw;
        height: 35px;
        @include alphabg($red, .93);
    } 

    span {
        position: relative;
        z-index: 1;
        display: block;
        width: 24px;
        height: 24px;
        left: 9px;
        top: 9px;
        overflow: hidden;

        @include xs {
            top: 5px;
            left: calc(50vw - 12px);
        }

        &:after {
            content: '\f129';
            font-family: 'fontawesome';
            position: absolute;
            z-index: 1;
            top: -3px;
            left: 8px;
            color: $white;
            font-size: 20px;
            transition: transform .4s $smooth;
            transform: translateY(-23px);
        }

        &:before {
            font-family: 'Roboto', sans-serif;
            content: '\2715';
            position: absolute;
            z-index: 1;
            top: 20px;
            left: 3px;
            color: $white;
            font-size: 20px;
            transition: transform .4s $smooth;
            transform: translateY(-23px);
        }
    }
}

.floating-chat-open {
    top: -60px;
    width: auto;

    @include xs {
        top: auto;
        bottom: 34px;
        width: 100%;
        border-bottom: 1px solid white;
    }

    .floating-chat__wrap {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
    
        &:after,
        &:before {
            display: none;
        }

        .text {
            display: inline-block;
            color: #ffffff;
            font-size: 10px;
            margin: 0;
        }

        svg {
            display: inline-block;
            width: 21px;
            height: 21px;
            fill: #ffffff;
            margin-right: 5px;
        }
    }
}


body.floating-chat-active {

    @include xs {
        overflow: hidden;
    }

    .floating-chat {
        transform: translateX(0);
    }
}