@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Footer
--------------------------------------------------*/
.footer{
	background: $white;
    margin: 0 auto;
    max-width: 1920px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    clear: both;
    position: relative;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;

    //prefooter
    .prefooter {
        background: $pink; 
        padding: 100px 0 40px 0;
        border-bottom: 1px solid #dfd9d6; 

        @include sm {
            padding: 40px 0 0px 0;
        }

        //wrap
        .wrap {
           display: flex;
           flex-direction: row;
           justify-content: space-between;
           align-items: flex-start;
           flex-wrap: wrap;
           
           //sitemap
           .sitemap {
               flex: 0 0 50%;
               max-width: 50%;
               display: flex;
               flex-wrap: wrap;
               flex-direction: row;
               margin: 0 -15px;
              
               @include sm {
                   max-width: calc(100% + 30px);
                   flex-basis: calc(100% + 30px);
                   order: 2;
               }

               .box {
                   flex: 0 0 33.333%;
                   max-width: 33.333%;
                   padding: 0 15px;
                   
                   @include md {
                       flex: 0 0 50%;
                       max-width: 50%;
                   }

                   @include sm {
                       flex: 0 0 33.333%;
                       max-width: 33.333%;
                       order: 2;
                   }

                   @include xs {
                       flex: 0 0 50%;
                       max-width: 50%;
                       order: 2;
                   }

                   @include xxs {
                       flex: 0 0 100%;
                       max-width: 100%;
                       order: 2;
                       display: flex;
                       flex-direction: row;

                       & > * {
                           flex: 0 0 50%;
                           max-width: 50%;
                       }
                   }

                   ul {
                       font-size: 14px;
                       line-height: 2;
                       margin-bottom: 60px;

                       @include xs {
                           margin-bottom: 30px;
                       }

                       li {
                           @include xs {
                               padding-bottom: 0;
                           }
                       }
                   }

                   /*&:last-child ul li {
                       font-size: 24px;
                       padding-right: 14px;
                   }*/
               } 
           }
            
           //description
           .descrition {
               flex: 0 0 40%;
               max-width: 40%;
               margin: 0 -15px;

               @include md {
                    flex: 0 0 50%;
                    max-width: 50%;
               }

               @include sm {
                   max-width: calc(100% + 30px);
                   flex-basis: calc(100% + 30px);
                   order: 1;
               }

               .box {
                    padding: 0 15px;

                    @include sm {
                        margin-bottom: 80px;
                    }

                   .text {
                      
                       .logo {
                            float: right;
                            margin: 0 0 20px 20px;

                            @include sm {
                                float: none;
                                margin: 0 auto 30px auto;
                                max-width: 20%;
                                text-align: center;
                            }

                            @include xxs {
                                max-width: 30%;
                            }
                       }
                   }
               }
           }
        }
    }

    //ending
    .ending {
        padding: 80px 0;

        @include xs {
            padding: 40px 0;
        }

        //wrap
        .wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding-bottom: 40px;

            @include xs {
                display: block;
                padding-bottom: 20px;
            }

            //service
            .service {
                font-size: 12px;
                flex: 0 0 50%;
                max-width: 50%;

                @include xs {
                    max-width: none;
                    padding-bottom: 20px;
                }

                .copyright {
                    padding-bottom: 5px;

                    small {
                      font-size: 12px;
                      font-weight: 500;
                      display: inline-block;
                    }

                    p {
                      display: inline-block;
                      margin-bottom: 0;
                    }

                    address {
                      display: inline-block;
                      margin-bottom: 0;
                    }
                }

                ul {
                    margin-bottom: 0;
                }
            }

            //certification
            .certification {
                flex: 0 0 40%;
                max-width: 40%;
                display: flex;
                flex-direction: space-around;
                justify-content: flex-end;
                align-items: center;

                @include xs {
                    max-width: none;
                    justify-content: flex-start;
                }

                .box {
                    padding: 0 10px;

                    .text {
                        font-weight: 700;
                        font-size: 16px;
                        margin-bottom: 0;
                    }

                    .eyelet {
                        display: block;
                        font-size: 12px;
                    }
                }
            }
        }

        //agency
        .agency {
            width: 100%;
            text-align: center;
            
            a {
                font-size: 12px;
                color: $dark-grey;
            }
        }
    }
}


/**
 * Cookie bar
 */
.rcc-panel {
    position: fixed;
    //bottom: 100px;
    bottom: -25px;
    z-index: 99;
    width: 100%;
    background: rgb(34, 34, 34); 
    border-bottom: 0px solid rgb(85, 85, 85);
    transform: translateY(100px);
    transition: transform linear .8s;

    @include xxs {
        bottom: -40px;
    }

    &.visible {
        transform: translateY(0);
    }

    .rcc-wrapper {
        display: flex;
        flex: 0 0 0;
        flex-flow: row wrap; 
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto; 
        max-width:1600px; 
        padding: 12px 20px;

        p, a {
            width: auto;
            color: #fff;
            margin: 0;
        }
        a{
            display: inline-block;
            text-decoration: none;
            padding: 5px 10px;
            border-radius: 2px;
            transition: all 0.2s;
        }
        .rcc-links {
            display: flex;
            flex-flow: row-reverse wrap;
            justify-content: space-between;
            flex: 1 0 0;
            .rcc-info-btn {
                padding-left: 5px;
                padding-top: 6px;
                text-decoration: underline;
                &:hover {
                    color: #555555!important;
                }
            }
            .rcc-accept-btn {
                background:#555555;
                &:hover {
                    background:rgba(255,255,255,0.5);
                }
            }
        } 
    }
}