@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Search Panel
--------------------------------------------------*/
.search-panel {
    position: fixed;
    z-index: 2;
    max-width: 1920px;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    margin: 0 auto;
    height: $search-panel-height;
    background: $white;
    transition: bottom .4s $smooth;

    @include sm {
        position: static;
        height: auto;
        padding: 10px 0;
    }

     @include notebook {
        height: $search-panel-height-sm;
    }

    .big-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include sm {
            display: block;
        }
    }

    .preface {
        flex: 0 0 41.666%;
        max-width: 41.666%;
        margin: 0 -15px;

        @include md {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include sm {
            max-width: none;
        }

        .box {
            padding: 20px 15px;

            @include notebook {
                padding: 5px 15px;
            }

            .h3 {
                font-size: 28px;
                margin-bottom: 10px;

                @include xl {
                    font-size: 22px;
                }

                @include md {
                    font-size: 17px;
                }

                @include notebook {
                    font-size: 17px;
                    margin-bottom: 4px;
                }
            }

            .img {
                display: inline-block;

                 @include sm {
                    width: 30px;
                }

                @include notebook {
                    width: 30px;
                }
            }

            .eyelet {
                margin-left: 10px;
                display: inline-block;
            }
        }
    }

    .search-bar-big {
         flex: 0 0 50%;
         max-width: 50%;
         position: relative;
         z-index: 1;
         @include sm {
            max-width: none;
         }

         @include xs {
             display: none;
         }

         .form-group {
             position: relative;
             z-index: 1;
             top: 16px;
             margin-bottom: 0;

             @include notebook {
                top: 0px;
             }
         }
    }
}
  
//header compact
body.header-compact {

    .search-panel {
        bottom: -$search-panel-height;
    }
}