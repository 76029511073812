@import 'variables';

/*--------------------------------------------------
Sidebar
--------------------------------------------------*/

 .sidebar {
    width: 16.666%;
    margin-right: 8.333%;
    float: left;

    @include xs {
        display: none;
    }

    &.sidebar-fixed {
        position: fixed;
        top: 75px;
        width: calc(16.666% - 30px);
    }

    &.sidebar-ending {
        position: absolute;
        top: auto;
        bottom: 0;
        width: calc(16.666% - 30px);
    }
}

.sidebar-content {
    width: 50%;
    float: right;
    margin-right: 25%;

    @include xl {
        width: 66.666%;
        margin-right: 8.333%;
    }

    @include sm {
        width: 75%;
        margin-right: 0;
    }

    @include xs { 
        width: 100%;
    }
}
