@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Modal
--------------------------------------------------*/

//modal-hidden
.modal-html {
    display: none;
    padding: 35px;

    .modal-header {
        margin-bottom: 30px;

        .h4 {
          max-width: 600px;
          line-height: 1.4;
          padding-right: 30px;
        }

        .ornament {
            margin-bottom: 0;
        }
    }

    .modal-content {
      
        .cta a.btn {
          margin-bottom: 0 !important;
        }
    }

    .modal-content + .modal-footer {
      margin-top: 30px;
    }

    .modal-footer {

        .btn {
            margin-bottom: 0;
        }
    }

    &.big {
        min-width: 70%;
    }
}

//modal close small
.fancybox-close-small {
    background: $light-grey;
    width: 35px;
    height: 35px;

    &:after {
        font-family: 'Roboto', sans-serif;
        content: '\2715';
        border-radius: 0;
        border: 0;
        width: 22px;
        height: 22px;
        top: 6px;
        right: 0.39em;
        line-height: 1.5;
        font-size: 16px;
        font-weight: 700;
    }

    &:hover {
        text-decoration: none;
        @include alphabg($dark-grey, .6);

        &:after {
            color: $white;
            background: none;
        }
    }
}

//fancybox button
.fancybox-button {
    color: $white;
    @include alphabg($red, .8);

    &.fancybox-button--arrow_right,
    &.fancybox-button--arrow_left {
        background: none;

         &:before {
             @include alphabg($red, .8);
         }

         &:after {
             position: relative;
             z-index: 2;
             top: 5px;
             color: $white;
             font-family: 'fontawesome';
             font-size: 30px;
             font-weight: 700;
             line-height: 0;
         }

         svg {
             display: none;
         }
    }

     &.fancybox-button--arrow_right {
         &:after {
            content: '\f105';
            right: -2px;
         } 
     }

     &.fancybox-button--arrow_left {
         &:after {
            content: '\f104';
            left: -2px;
         }
     }

     &.fancybox-button--close {

         svg {
             display: none;
         }

         &:after {
            font-family: 'Roboto', sans-serif;
            position: relative;
            z-index: 2;
            top: 3px;
            color: $white;
            font-size: 22px;  
            line-height: 0;
            font-weight: 700;
            content: '\2715'; 
         }
     }
}

