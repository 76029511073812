@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Typography
--------------------------------------------------*/

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;

    @include sm {
        font-size: 14px;
    }
}

strong {
    font-weight: 700;
}

h1, .h1 {
    font-weight: 700;
    font-size: 64px;
    line-height: 1.2;
    margin-bottom: 42px;
    
    @include xl {
        font-size: 50px;
    }

    @include md {
        font-size: 36px;
        margin-bottom: 20px;
    }

    @include sm {
        font-size: 30px;
    }

    @include xs {
        font-size: 26px;
    }
}

h2, .h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.1;
    margin-bottom: 37px;

    @include md {
        font-size: 30px;
    }

    @include sm {
        font-size: 25px;
        margin-bottom: 20px;
    }
}

h3, .h3 {
   font-weight: 700;
   font-size: 32px;
   margin-bottom: 37px;
    line-height: 1.3;

    @include md {
        font-size: 26px;
        margin-bottom: 30px;
        line-height: 1.2;
    }

    @include sm {
        font-size: 20px;
        margin-bottom: 15px;
    }
}

h4, .h4 {
   font-weight: 700;
   font-size: 24px;
   margin-bottom: 30px;

   @include md {
        font-size: 22px;
   }

   @include sm {
        font-size: 18px;
   }
}

h5, .h5 {
   font-weight: 700;
   font-size: 18px;
   margin-bottom: 26px;
}

h6, .h6 {
   font-weight: 700;
   font-size: 16px;
   margin-bottom: 20px;
} 

 
//editor style
.disease-content .text,
.main-content .text-content {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
        font-size: 33px;
        line-height: 1.1; 
        margin-top: 80px;
        margin-bottom: 20px;

        @include md {
            font-size: 26px;
            margin-top: 50px;
        }

        @include sm {
            font-size: 24px;
            margin-top: 40px;
        }

        @include xxs {
            font-size: 20px;
        }
    }

    h2:first-child {
        margin-top: 20px;
    }

    .box {
        &>*:last-child {
            margin-bottom: 0;
        }
        .text {
            p:last-child {
                margin-bottom: 0;
            }
        }
    }    
}