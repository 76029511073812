@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Contacts
--------------------------------------------------*/
.contacts {
    margin-bottom: 130px;

    @include sm {
        margin-bottom: 80px;
    }

    .big-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include sm {
            display: block;
        }

        .map {
            flex: 0 0 50%;
            max-width: 50%;

            @include sm {
                width: 100%;
                max-width: none;
                margin-bottom: 30px;
            }

            //da tablet in su
            @media (min-width: 990px) {
                a {
                    .img svg {
                        transition: transform 1s $smooth;
                    } 

                    &:hover { 

                        .img svg {
                            transform: scale(1.02);
                        }
                    }
                }
            }
        }

        .addresses {
            flex: 0 0 33.333%;
            max-width: 33.333%;
            margin-right: 8.333%;

            @include sm {
                width: 100%;
                max-width: none;
                margin: 0 -15px 0 -15px;
            }

            .box {
                padding: 0 15px 0 15px;

                @include sm {
                    padding: 0 15px 0 15px;
                    margin-bottom: 30px;
                }

                .h2 {
                    margin-bottom: 18px;
                }

                .h4 {
                    font-size: 22px;
                    margin-bottom: 18px;

                    @include xs {
                        font-size: 18px;
                    }
                }

                .ornament {
                     margin-bottom: 18px;
                }

                .eyelet {
                    font-size: 16px;
                    font-weight: 700;
                }

                .text {
                    margin-bottom: 18px;

                    p {
                        margin: 0;
                        font-size: 16px;
                    }
                }

                .cta .btn {
                    margin-bottom: 0;
                    margin-top: 30px;
                }
            }
        }
    }
}