@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Sticky Search Bar
--------------------------------------------------*/
.sticky-bar  {
    position: fixed;
    z-index: 11;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1920px;
    /* bottom: -$sticky-height; */
    width: 100%;
    height: $sticky-height;
    background: $pink;
    border-bottom: 1px solid #dfd9d6;
    transition: bottom .4s $smooth;


     &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(to right, #b81a23, #de3f1a, #f2681a, #fda816);
    }

    @include md {
        height: $sticky-height-compact;
        bottom: -$sticky-height-compact;
    }

    @include xs {
        bottom: -1px;
        transition: bottom .4s $smooth .4s;
    }

    &.static {
        position: relative;     
        z-index: 1;   
    }

    .big-container {
        @include md {
            width: 100%;
        }
    }

   
    &:before {
        @include clearfix;
    }

    .form-group {
        margin-bottom: 0;
    }

    .sticky-search {
        float: left;
        width: 58.333%;
        position: relative;
        top: 13px;

        @include md {
            top: 3px;
            width: 50%;
            padding: 0 10px;
        }

        @include xs {
            top: 0;
            width: 33.333%;
            padding: 0;
        }

        .form {
            display: block;

            @include xs {
                display: none;
            }
        }

        .cta {
            display: none;
            border: 0;
            @include xs {
                display: block;
            }

            a {
                @include xs {
                    display: block;
                }
            }
        }
    }

    .sticky-cta {
        float: right;
        width: 41.666%;
        background: $white;
        
        @include md {
            width: 50%;
        }

        @include xs {
            width: 66.666%;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 5%;
            background: $white;
            height: 100%;

            @include md {
                display: none;
            }
        }

        .cta {
            display: flex;
            flex-direction: row;
        }
    }
}


//header compact
body.header-compact {

    .sticky-bar  {
        bottom: -1px;
        transition: bottom .4s $smooth .4s;
    }
}