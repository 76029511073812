@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Header
--------------------------------------------------*/
.header {
	background: $white;
    position: fixed;
    z-index: 12;
    width: 100%;
    height: $header-height;
    border-bottom: 1px solid $white;
    transition: all .3s $smooth;

    &:after {
        @include clearfix;
    }
    
    .small-cta-logo {
        display: none;
    }

    .logo {
        position: relative;
        z-index: -1;

        a {
            display: inline-block;
        }

        .vertical,
        .horizontal {
            position: absolute;
        }

        .vertical {
            z-index: 1;
            opacity: 1;
            padding: 15px 0;
            pointer-events: all;
            transition: opacity .2s $smooth;
            
            img {
              max-width: 90%;
              max-height: 100px;
            }
        }

        .horizontal {
            z-index: 2;
            opacity: 0;
            padding: 10px 0;
            pointer-events: none;
            transition: opacity .2s $smooth;

            img {
                max-height: 57px;
            }

            // @include lg {
            //     padding: 12px 0;

            //     img {
            //         max-width: 90%;
            //     }
            // }

            // @include md {
            //     padding: 22px 0;

            //     img {
            //         max-width: 55%;
            //     }
            // }

            // @include sm {
            //     padding: 15px 0;

            //     img {
            //         max-width: 70%;
            //     }
            // }
        }
    }
    
} 

//mobile
 @include sm {
    body {
        overflow-x: hidden;
        overflow-y: auto;

        .header {
            height: $header-height-compact;
            border-bottom: 1px solid $red;
      
            .logo {

                .vertical {
                    opacity: 0;
                    pointer-events: none;
                }

                .horizontal {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            .nav-main {
                margin-top: 25px;

                ul li .sub {
                    top: $header-height-compact;
                }
            }

            .nav-service {
                transform: translateY(-30px);
            }
        }
    }
}
 

//header compact
body.header-compact {
    overflow-x: hidden;
    overflow-y: auto;

    .header {
        height: $header-height-compact;
        border-bottom: 1px solid $red;
      
        .logo {

            .vertical {
                opacity: 0;
                pointer-events: none;
            }

            .horizontal {
                opacity: 1;
                pointer-events: all;
            }
        }

        .nav-main {
            margin-top: 25px;

            ul li .sub {
                top: $header-height-compact;
            }
        }

        .nav-service {
            transform: translateY(-30px);
        }
    }
}

.cc-banner.cc-window, .cc-floating.cc-window {
    position: fixed;
    z-index: 9999;
    background: white;
    width: 100%;
    text-align: center;
    padding: 10px;
    background: #f5f5f5;
    top: $header-height;
    transition: all .6s $smooth;

    @include md {
        top: 75px;
    }
}

body.header-compact {
    .cc-banner.cc-window, .cc-floating.cc-window {
        top: $header-height-compact;

        @include md {
            top: 75px;
        }
    }
}