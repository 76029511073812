@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Floating Panel
--------------------------------------------------*/
.floating-panel {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    background: $pink;
    width: 50%;
    padding: 60px 0 100px 0;

    @include xs {
        width: 100%;
        position: static;
        padding: 0 0 60px 0;
        margin-bottom: 60px;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px $pink inset;
    }

    .img {
        position: relative;
        z-index: 1;
        display: block;
        height: 0;
        padding-bottom: 60%;
        overflow: hidden;
        margin-left: 10%;
        
         @include xs {
            margin-left: 0;
        }

        svg {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .wrap {
        position: relative;
        z-index: 1;
        width: 80%;
        margin: 0 auto;

        @include xs {
            width: 90%;
            margin: 0 auto;
        }

        .box {
            margin-bottom: 44px;

             @include xl {
                margin-bottom: 30px;
            }

            &:last-child {
                margin-bottom: 0;
                padding-top: 20px;
            }

            .h1 {
                padding-top: 23px;
                margin-bottom: 23px;
                font-size: 55px;
                color: $red;

                @include xl {
                    padding-top: 20px;
                    margin-bottom: 20px;
                    font-size: 42px;
                }

                @include md {
                    font-size: 40px;
                }

                @include sm {
                    font-size: 33px;
                }
            }

            .h4 {
                text-transform: uppercase;
                font-size: 21px;

                @include xl {
                    margin-bottom: 20px;
                }

                @include sm {
                    font-size: 19px;
                }
            }

            .h6 {
                text-transform: uppercase;

                @include xl {
                    margin-bottom: 20px;
                }
            }

            .text {
                @include xl {
                    font-size: 14px;
                    margin-bottom: 20px;
                }
            }

            .icon {

                img {
                    max-width: 50px;                    
                    margin: 0 auto 10px auto;

                    @include md { 
                        max-width: 40px;
                    }
                }
            }

            .ornament {
                background: $red;
                margin-bottom: 26px;

                @include xl { 
                    margin-bottom: 20px;
                }
            }

            .carousel {
                @include xl {
                    margin-bottom: 20px;
                }
            }

            .cta {
                
                @include md { 
                    font-size: 13px;
                }

                .btn {
                    font-size: 14px;
                    margin-bottom: 0;
                }
            }

            .form-group {
                margin-bottom: 20px;
            }
        }

    }

    &.fixed {
       
        .wrap {
            position: fixed;
            z-index: 10;
            top: 0;
            left: 50%;
            width: 50%;
            max-width: 960px;
            height: 100vh;
            background: $pink;
            padding: 70px 5% 0 5%;
        }
    }

    &.ending {
       position: absolute;
       top: auto;
       left: auto;
       right: 0;
       bottom: 0;

        .wrap {
            position: relative;
            z-index: 1;
            width: 80%;
            margin: 0 auto;
        }
    }
}


  