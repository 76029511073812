@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Banner
--------------------------------------------------*/
.banner {
    padding-bottom: 90px;

    @include sm {
        padding-bottom: 40px;
    }

    a {
        color: $white;
           
        &:hover {
            @include alphatxt($white, .9);
        }
    }

    .wrap {
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        cursor: pointer;
        box-shadow: $shadow;

        .box {
            position: absolute;
            z-index: 1;
            top: 0; 
            left: 0;
            width: 70%;
            height: 100%;
            padding: 30px 150px 30px 30px;

            @include xl {
                padding: 15px 70px 15px 15px;
            }

            @include sm {
                padding: 30px 50px 30px 30px;
            }

            @include xs {
                position: static;
                padding: 30px;
                width: 100%;
            }

            .h3 {
                margin-bottom: 5px;
                font-size: 26px;

                @include xl {
                     font-size: 24px;
                }

                @include md {
                    font-size: 20px;
                }

                @include xs {
                    color: $dark-grey;
                }
            }

            .text {
                font-size: 20px;
                margin-bottom: 0;

                @include xl {
                     font-size: 18px;
                }

                @include md {
                    font-size: 16px;
                }

                @include xs {
                    color: $dark-grey;
                }
            }

            .btn {
                position: absolute;
                z-index: 1;
                bottom: 30px;
                right: -40px;
                margin-bottom: 0;

                @include xl {
                    right: -100px;
                    bottom: 15px;
                }

                @include xs {
                    left: 30px;
                    bottom: 30px;
                    right: auto;
                }

                @include xxs {
                    left: 30px;
                    bottom: 15px;
                    right: auto;
                }
            }
        }
    }
}