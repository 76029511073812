@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Focus
--------------------------------------------------*/
.focus {
    margin: 0 -15px 0 -15px;

    @include sm {
        margin: 0 -15px 0 -15px;
    }

    &:after {
        @include clearfix;
    }

    .left {
        float: left;
        width: 41.666%;
        padding: 0 15px;
        
        @include sm {
            width: 50%;
        }

        @include xs {
            float: none;
            width: auto;
            margin-bottom: 0px;
        }

        &:nth-child(2n+1) {
            clear:both
        }        
    }

    .right {
        float: right;
        width: 41.666%;
        padding: 0 15px;

        @include sm {
            width: 50%;
        }

        @include xs {
            float: none;
            width: auto;
            margin-bottom: 0px;
        }

    }

    .box {
        padding-bottom: 130px;

        @include sm {
            padding-bottom: 80px;
        }

        @include xs {
            padding-bottom: 30px;
        }
    }
}
