@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Box
--------------------------------------------------*/

//Box
.box {
	
    a {
        color: $red;

        &:hover {
            @include alphatxt($red, .8);
            text-decoration: none;
        }
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
         a {
            color: $dark-grey;

            &:hover {
                color: $red;
                text-decoration: none;
            }
        }
    }

    //da tablet in su
    @media (min-width: 990px) {
        a {
            .img svg {
                transition: transform 1s $smooth;
            } 

            &:hover { 

                .img svg {
                    transform: scale(1.02);
                }
            }
        }
    }

    //news
    &.news {
        display: flex;
        flex-direction: row;
        
        @include sm {
            display: block;
        }

        .date {
            flex: 0 0 30%;
            text-transform: uppercase;

            @include sm {
                margin-bottom: 10px;
            }

            p {
                margin: 0;
                padding: 0;
                color: $light-grey;
                font-size: 13px;
                text-align: center;
                float: left;
            }

            span {
                font-size: 86px;
                display: block;
                line-height: 0.9;
                font-weight: 700;

                @include md {
                    font-size: 70px;
                }
            }

              &:after {
                    @include clearfix;
                }
        }

        .img {
            flex: 0 0 30%;
            text-transform: uppercase;

            @include sm {
                margin-bottom: 10px;
            }
        }

        .wrap {
            flex: 0 0 70%;
        }

        &-subtitle {

            .h4 {
                margin-bottom: 10px;
            }

            .eyelet {
                padding-bottom: 20px;
                margin-bottom: 0 !important; 
                font-size: 14px;
            }
        }
    } 

    //tit-over
    &.tit-over {

        a {
            position: relative;
            z-index: 1;
            top: 0;
            left: 0;
            display: block;
        }

        .h2,
        .eyelet {
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            display: inline-block;
            color: $dark-grey;
            background: $white;
            margin: 0;
            padding: 15px 40px 15px 0px;

            @include xs {
                position: static;
                padding: 0;
                background: none;
                margin-bottom: 20px;
            }
        }

        .eyelet {
          margin-bottom: 0 !important;
          padding: 10px 20px;

          @include xs {
            background: $white;
            position: absolute;
          }
        }

        .img {
            padding-bottom: 56.25%;

            @include xs {
                display: none;
            }
        }

        .cta {

            a {
                display: inline-block;
                position: static;
            }
        }

        //da tablet in su
        @media (min-width: 990px) {
            a { 

                .h2,
                .eyelet {
                    transition: color 1s $smooth;
                }

                .img svg{
                    transition: transform 1s $smooth;
                }

                &:hover { 
                    
                    .h2 {
                        color: $red;
                    }

                    .img svg {
                        transform: scale(1.02);
                    }
                }
            }
        }       
    }
} 
 