@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Spotlight
--------------------------------------------------*/

.spotlight {
    
    .spotlight__wrapper {
        background: #f4e6e5;
        min-height: 620px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include md {
            min-height: 400px;
        }

        @include sm {
            display: block;
        }

        .spotlight__primary {
            flex: 0 0 50%;
            max-width: 50%;
            position: relative;

            //ie11 hack
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {  
                height: 620px;
            }

            @include sm {
                height: 290px;
                width: 100%;
                max-width: none;
            }
        }
    
        .spotlight__secondary {
            flex: 0 0 50%;
            max-width: 50%;
            position: relative;

            //ie11 hack
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {  
                height: 620px;
            }

            @include sm {
                height: 200px;
                width: 100%;
                max-width: none;
            }

            &:before,
            &:after {
                content: '';
                background: #f9f1f1;
                position: absolute;
            }
    
            &:before {
                top: 0;
                left: 50%;
                width: 4px;
                height: 100%;
            }
    
            &:after {
                top: 50%;
                left: 0;
                width: 100%;
                height: 4px;
            }
        }
    }

    .spotlight__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
    }

    .spotlight__content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
        
        .spotlight__item {
            flex: 0 0 50%;
            max-width: 50%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #292b2c;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 700;
            text-decoration: none;
            transition: all .6s $smooth;
            padding: 20px;
            text-align: center;
            line-height: 1.1;

            @include md {
                font-size: 16px;
            }

            &:hover {
                background: #b4111a1f;
            }
        }
    }
}