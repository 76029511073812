@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Related
--------------------------------------------------*/
.related {
    border-top: 1px solid #cfc5c5;
    padding: 90px 0;

    @include md {
        padding: 40px 0;
    }

    .h3 {
        margin-bottom: 60px;

        @include md {
            margin-bottom: 30px;
        }
    }

   .listing {
      .no-thumb {
        width: 88%;
        padding-bottom: calc(100% - 30px);
      }
   }

   .search-results-content {
     .listing {
       .no-thumb {
         width: 100%;
         padding-bottom: 70%;
       }
     }
   }
}

//related cta
.related-cta {

  .left {
    float: left;
    width: 50%; 
  }

  .right {
    float: right;
    width: 50%;
  }

  &:after {
    @include clearfix;
  }

  .cta a {
    margin-right: 15px;
  }
}

.related-cta + .media {
  border-top: 0;
}