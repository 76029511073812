@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Sticky Info
--------------------------------------------------*/

//floating info
.floating-info, .floating-sharing {
    position: absolute;
    z-index: 0;
    bottom: calc(#{$sticky-height} - 1px );
    right: 0;
    pointer-events: none;

    @include md {
        bottom: calc(#{$sticky-height-compact} - 1px );
    }

    @include xs {
         width: 100%;
    }

    .wrap {
        position: relative;
        z-index: 1;
        width: 300px;
        background: $pink;
        box-shadow: $shadow;
        padding: 40px;
        transform: translateX(103%);
        transition: transform .4s $smooth; 

        @include xs {
            width: 100vw;
            margin-bottom: 34px;
            box-shadow: 0px -8px 20px 0px rgba(0, 0, 0, .2); 
        }

        .box {
            padding: 0;

            .h4 {
                color: $red;
                margin-bottom: 18px;
                font-size: 20px;

                p {
                    margin-bottom: 5px;
                }
            }

            .ornament {
                background: $red;
                margin-bottom: 18px;
            }

            .text {
                font-size: 16px;
                margin-bottom: 15px;

                 p {
                    margin-bottom: 0;
                }
            }

            .cta {
                 font-size: 16px;

                 .btn-inline {
                     margin-bottom: 18px;
                 }

                .btn-animate {
                    margin-bottom: 0;
                }
            }
        }
    }

    .floating-info-toggle, .floating-sharing-toggle {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0px;
        width: 40px;
        height: 40px;
        background: $red;
        pointer-events: all;

        @include xs {
            top: auto;
            bottom: -1px;
            width: 100vw;
            height: 35px;
            @include alphabg($red, .93);
        } 
         
        span {
            position: relative;
            z-index: 1;
            display: block;
            width: 24px;
            height: 24px;
            left: 9px;
            top: 9px;
            overflow: hidden;

            @include xs {
                top: 5px;
                left: calc(50vw - 12px);
            }

            &:after {
                content: '\f129';
                font-family: 'fontawesome';
                position: absolute;
                z-index: 1;
                top: -3px;
                left: 8px;
                color: $white;
                font-size: 20px;
                transition: transform .4s $smooth;
            }

            &:before {
                font-family: 'Roboto', sans-serif;
                content: '\2715';
                position: absolute;
                z-index: 1;
                top: 20px;
                left: 3px;
                color: $white;
                font-size: 20px;
                transition: transform .4s $smooth;
            }
        }
    }
}

.floating-sharing {
  bottom: calc(300px - 1px);
  .floating-sharing-toggle {
    span:after {
      content: '\f1e0';
      left: 3px;
    }
    // .fa {
    //   color: white;
    //   top: 10px;
    //   left: 10px;
    //   position: absolute;
    //   &.fa-share-alt:before {
    //     font-size: 20px;
    //   }
    // }
  }
}

//animation
body.floating-info-open {
    .floating-info {
        pointer-events: all;

        .wrap {
            transform: translateX(0);
        }
    }

    .floating-info-toggle {

        @include xs {
            @include alphabg($red, 1);
        } 

        span {
            &:after {
                transform: translateY(-23px);
            }

            &:before {
                transform: translateY(-23px);
            }
        }
    }
}

body.floating-sharing-open {
  .floating-sharing {
      pointer-events: all;

      .wrap {
          transform: translateX(0);
      }
  }

  .floating-sharing-toggle {

      @include xs {
          @include alphabg($red, 1);
      } 

      span {
          &:after {
              transform: translateY(-23px);
          }

          &:before {
              transform: translateY(-23px);
          }
      }
  }
}