
//--------------------------------------------------
//Media Query
//--------------------------------------------------
$xl-width: 1560px;
$lg-width: 1499px;
$md-width: 1199px;
$sm-width: 991px;
$xs-width: 767px;
$xxs-width: 575px;


@mixin xl {
  @media (max-width: #{$xl-width}) {
    @content;
  }
} 

@mixin lg {
  @media (max-width: #{$lg-width}) {
    @content;
  }
} 

@mixin md {
  @media (max-width: #{$md-width}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$xs-width}) {
    @content;
  }
}
 
@mixin xxs {
  @media (max-width: #{$xxs-width}) {
    @content;
  }
}

@mixin notebook {
  @media screen and (max-height: 768px)  and (max-width: 1366px) {
    @content;
  }
}

//tablet portrait
@mixin tablet-portrait {
  @media screen and (orientation: portrait) 
  and (max-device-width: 1024px) {
      @content;
  }
}

//tablet landscape
@mixin tablet-landscape {
  @media screen and (orientation: landscape) 
  and (max-device-width: 1024px) {
      @content;
  }
}

//phone landscape
@mixin phone-landscape {
  @media screen and (orientation: landscape) 
  and (max-device-width: 768px) {
      @content;
  }
}


//--------------------------------------------------
//Mixins
//--------------------------------------------------

//Centerize
@mixin centerize {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

//Alpha Clear Fix
@mixin clearfix {
    content: '';
    display: table;
    clear: both;
}

//Alpha Txt
@mixin alphatxt($color, $perc) {
    color: rgba( $color, $perc ) ;
}

//Alpha Bg
@mixin alphabg($color, $perc) {
    background: rgba( $color, $perc ) ;
}    
