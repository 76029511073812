@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Dashboard
--------------------------------------------------*/
.dashboard {
    margin-bottom: 150px;

    @include sm {
        margin-bottom: 80px;
    }

    @include xs {
        margin-bottom: 40px;
    }

    .big-container {
         &:after {
            @include clearfix;
        }
    }

    //avatar
    .avatar {
        float: left;
        text-align: center;
        margin-right: 20px;
        width: 236px;

        @include sm {
            width: 140px;
        }

        @include xs {
            width: 100px;
        }

        @include xxs {
            display: none;
        }

        .wrap {
          
            .cta {
                font-weight: 700;
                background: rgba(235, 228, 224, 0.8);
                color: $dark-grey;
                font-weight: 700;
                padding: 6px 15px;
                font-size: 14px;
                margin-bottom: 1px;

                @include sm {
                  display: none;
                }

                a {
                  color: $dark-grey;

                  &:hover {
                    color: $red;
                    text-decoration: none;
                  }
                }

                .btn {
                    margin-bottom: 0px;

                    @include sm {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    //user info
    .user-info {
        float: left;
        width: 70%;

         @include xs {
           width: 60%;
        }

         @include xxs {
           width: 100%;
         }

        .box {
            
            .text {
                font-size: 22px;

                @include sm {
                    font-size: 16px;
                    
                }

                @include xxs {
                    font-size: 14px;
                    margin-bottom: 0;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    //dashboard subnav
    .dashboard-subnav { 
        float: left;
        width: 90%;
        margin: 0 -15px;

        @include sm {
            margin: 0 -8px;
        }

        @include xs {
            margin: 0;
            width: 100%;
            clear: both;
            padding-top: 30px;
        }

        .item {
            width: 180px;
            text-align: center;
            padding: 0 15px;
            float: left;

            @include sm {
                width: 33.333%;
                padding: 0 8px;
            }

            @include xxs {
                float: none;
                width: 100%;
                padding: 0;
                margin-bottom: 20px;
                text-align: left;
            }

             &:after {
                @include clearfix;
            }

            a {
                font-weight: 700;

                .icon {
                    display: flex;
                    flex-direction: column; 

                    @include xxs {
                        flex-direction: row;
                        align-items: center;
                    }

                    i {
                        color: $red;
                        display: block;
                        font-size: 50px;
                        padding-bottom: 10px;

                        @include xl {
                            font-size: 40px;
                        } 

                        @include xxs {
                            text-align: center;
                            padding: 0;
                            flex: 0 0 25%;
                        }
                    }

                    span {
                        color: $dark-grey;
                    }
                }
                  
                &:hover {
                    text-decoration: none;

                    span {
                        color: $red;
                    }
                }
            }
        }

        .cta {
            display: none;
            padding-bottom: 30px;

            .btn {
                font-size: 14px;
                padding-bottom: 5px;
                margin: 0;
            }

            @include xs {
                display: block;
            }
        }
    }

    //Compact
    &.compact {
        margin-bottom: 60px;

        .dashboard-content {
            background: $pink; 
            
            &:after {
                @include clearfix;
            }

            .avatar {
                margin: 5px 10px 0 6px;
                width: 27px;

                @include sm {
                    display: none;
                }
            }

            .user-info {
                margin-top: 9px;
                width: auto;
                font-size: 14px;

                @include sm {
                    display: none;
                }

                ul {
                    margin: 0;

                    li {
                        display: inline-block;
                        margin-right: 10px;

                        @include xs {
                            display: block;
                            margin-right: 0;
                        }

                        .inline-logout {
                            display: none;

                            @include xs {
                                display: block;
                            }
                        }
                    }
                }

                .btn {
                    margin: 0;
                }
            }

            .user-logout {
                float: right;

                @include sm {
                    float: none;
                    clear: both;
                }

                a {
                    display: block;
                    font-size: 14px;
                }

                .btn {
                    margin-bottom: 0;

                     @media (max-width: 420px) { 
                         padding: 5px 15px;
                     }
                }

                ul {
                    margin-bottom: 0;

                    @include sm {
                        display: flex;
                        flex-direction: row;
                    }

                    @media (max-width: 420px) {
                        display: block;
                    }

                    li {
                        display: inline-block;
                        padding-bottom: 0;

                        @include sm {
                            flex: 1 0 auto;
                            text-align: center;
                            border-right:3px solid $pink;

                            &:last-child {
                                border: 0;
                            }
                        }

                        @media (max-width: 420px) {
                            display: block;
                            padding-bottom: 2px;
                            border: 0;
                        }
                    }
                }
            }
        }
    }

    &.dashboard-bar {
      display: none;

      @include sm {
        display: block;
      }
    }
}


/*--------------------------------------------------
Login
--------------------------------------------------*/

//login-header
.login-header { 

    .box {
        padding-bottom: 30px;

        .h3 {
            margin-bottom: 8px;
        }

        .h4 {
            font-size: 22px;
            margin-bottom: 30px;
            font-style: italic;
            font-weight: normal;
        }
    }
}

//login-panels
.login-panels {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
   padding-bottom: 90px;

    @include sm {
        padding-bottom: 40px;
    }
    
    @include xs {
        display: block;
    }
    
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px $pink inset;
    }

    .item {
        flex: 0 0 48%;
        background: $pink;
        box-shadow: $shadow;
        
        @include xs {
            margin-bottom: 30px;
        }

        .wrap {
            padding: 40px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include xs { 
                margin-bottom: 20px;
            }
        } 
    }
}

.login-footer {
    padding-bottom: 90px;

    @include sm {
        padding-bottom: 40px;
    }

    .carousel {
        width: 50%;
        margin: 0 auto;

        @include md {
             width: 70%;
        }

        @include xs {
             width: 100%;
        }
    }
}