@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Accordion
--------------------------------------------------*/

//media
.media {
    padding: 90px 0;
    border-top: 1px solid #cfc5c5;

    @include md {
        padding: 70px 0;
    }

    @include sm {
        padding-bottom: 40px;
    }
}

//accordion
.accordion {
   
    .collapse.active {
        display: block;
    }

    .accordion-heading {
        background: $pink;
        color: $dark-grey;
        margin-bottom: 25px;

        .accordion-header {
            position: relative;
            z-index: 1;

            h1, h2, h3 {
              text-decoration: none;
              padding: 0;
              font-size: 16px;
              line-height: 24px;
              font-weight: 400;
              margin-bottom: 0;
              display: inline-block;
            }

            a {
                display: block;
                padding: 10px 50px 10px 15px;
                color: $dark-grey;
                text-decoration: none;

                &.collapsed {
                    color: $red;
                    text-decoration: none;
                }

                &:hover {
                    @include alphabg($red, .2);
                }
            }

     
        }
    }

    .accordion-collapse {

        .accordion-content {
            margin-bottom: 25px;

            .wrap {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: 0 -15px;

                @include xxs {
                    margin: 0 -5px;
                }

                .box {
                    flex: 0 0 25%;
                    max-width: 25%;
                    padding: 0 15px;
                    margin-bottom: 40px;

                    @include sm {
                        flex: 0 0 33.333%;
                        max-width: 33.333%;
                    }

                    @include xxs {
                        flex: 0 0 50%;
                        max-width: 50%;
                        padding: 0 5px;
                    }

                    .img {
                        margin-bottom: 15px;

                        @include sm {
                            padding-bottom: 60%;
                        }
                    }

                    a {
                        color: $dark-grey;

                        &:hover {
                            color: $red;
                        }
                    }
                }
            }
        }
    }

    .accordion {
        
        &:last-child {

            .accordion-heading {
                margin-bottom: 0;
            }

            .accordion-collapse {
                margin-top: 25px;
            }
        }
    }
}


//arrow
span.accordion-arrow {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    overflow: hidden;
    pointer-events: none;

        &:after,
        &:before {
        position: absolute;
        font-family: 'fontawesome';
        color: $red;
        font-size: 30px;
        font-weight: 700;
        line-height: 0;
        transition: transform .4s $smooth;

        @include xs {
            font-size: 25px;
        } 
    } 

    &:after {
        top: 11px;
        right: 7px;
        content: '\f105';
        transform: translateX(25px);

        @include xs {
            transform: translateX(29px);
        }
        }

        &:before {
        top: 13px;
        left: -25px;
        content: '\f107';
        transform: translateX(25px);

        @include xs {
            transform: translateX(29px);
        } 
    }
}

.faq-title span.accordion-arrow {
    display: none;

    @include xs {
        display: block;
    }
}

.faq-title.collapsed span.accordion-arrow,
a.collapsed + span.accordion-arrow {
        &:after {
        top: 11px;
        right: 7px;
        content: '\f105';
        transform: translateX(0);
        }

        &:before {
        top: 13px;
        left: -25px;
        content: '\f107';
        transform: translateX(0);
    }
}
