@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Porta Pinciana
--------------------------------------------------*/
.porta-pinciana {
    
    .header {
        background: #f9f6f4;
        border-bottom: 1px solid $red;

        .vertical {
            padding: 35px 0;
        }
    }

    &.header-compact .header .nav-main {
        margin-top: 7px;
    }

    .small-cta-logo {
        display: inline-block;
    }

    .section {
        margin-bottom: 128px;

        @include md {
            margin-bottom: 64px;
        }

    }

    .focus {
        @include xs {
            margin: 0;
        }

        .big-container {
            & > .h2 {
                text-transform: uppercase;
            }
            
            & > .ornament {
                @include xs {
                    margin-bottom: 64px;
                }
            }
        }

        .left,
        .right {
            margin: 0 -15px;
            margin-bottom: 100px;

            @include xs {
               margin: 0;
               padding: 0;
            }
        }
    }

    .main-slider {
        .item {
            height: 460px;

            &:after {
                content: '';
                background: rgba(0,0,0,0.2);
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .h2 {
                font-size: 40px;
            }

            .text {
                font-size: 18px;
            }
        }
    }

    .cover--full {
        height: 460px;
        position: relative;
        
        @include tablet-landscape {
            height: auto;
        }

        @include sm {
            height: auto;
        }
        
        .big-container {
            position: relative;
            z-index: 2;
            height: 100%;

            @include tablet-landscape {
                position: static;
            }

            @include sm {
                position: static;
            }
        }

        &:after {
            content: '';
            background: rgba(0,0,0,0.2);
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include tablet-landscape {
                height: 300px;
            }

            @include sm {
                height: 300px;
            }
        }

        .cover__wrapper {
            display: flex;
            align-items: center;
            height: 100%;

            @include tablet-landscape {
                height: auto;
                display: block;
            }

            @include sm {
                height: auto;
                display: block;
            }
        }

        .cover__bg {
            background-size: cover;
            background-position: center center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include tablet-landscape {
                position: static;
                height: 300px;
                margin-bottom: 32px;
            }

            @include sm {
                position: static;
                height: 300px;
                margin-bottom: 32px;
            }
        }

        .box {
            max-width: 800px;
        }

        .h2 {
            font-size: 40px;
            color: white;

            @include md {
                font-size: 30px;
            }

            @include tablet-landscape {
                color: #292b2c;
            }

            @include sm {
                color: #292b2c;
            }
    
            @include xs {
                font-size: 20px;
            }
        }

        .text {
            font-size: 18px;
            color: white;
        
            @include tablet-landscape {
                color: #292b2c;
            }

            @include sm {
                color: #292b2c;
            }
            
            @include xs {
                font-size: 16px;
            }
        }

        .btn {
            margin:0;
        }
    }

    .news-comp {
        .big-container {
            & > .h2 {
                text-transform: uppercase;
            }
        }

        .h4 {
            margin-bottom: 16px;
        }

        .listing-grid {
            padding-bottom: 0;

            @include sm {
                .box {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
