@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Buttons
--------------------------------------------------*/

//btn
.btn-disabled {
    pointer-events: none;
    opacity: 0.6;
    transition: opacity .6s $smooth;
}
.btn {
    cursor: pointer;
    border: 0;
    margin-bottom: 27px; 

    &.btn-inline {
        display: block;
        font-size: 16px;
        font-weight: 700;
        color: $red;

        @include sm {
            font-size: 14px;
        }
    }

    &.btn-animate {
        color: $white;
        font-weight: 700;
        background: $red;
        padding: 8px 28px;
        display: inline-block;

        @include sm {
            padding: 6px 15px;
        }

        &:hover {
            color: $white;
            text-decoration: none;
            @include alphabg($red, .8);
        }

        &.btn-ghost {
            color: $red;
            background: transparent;
            border: 1px solid $red;

            &:hover {
                color: $red;
                @include alphabg($red, .1);
            }
        }
    }

    &.btn-search {
        background: transparent;

        &:after {
            color: $dark-grey;
            content: '\f002';
            font-family: 'fontawesome';
            font-size: 30px;

            @include md {
                font-size: 20px;
            }
        }
    }

    &.btn-sticky {
        display: inline-block;
        background: $white;
        margin-bottom: 0;
        padding: 20px 19px 15px 19px;
        flex: 0 0 50%;
        border-left: 1px solid #dfd9d6;
        position: relative;
        z-index: 1;
        color: $dark-grey;
        font-weight: 700;
        transition: all .4s $smooth;
        
        @include md {
            padding: 12px 19px 6px 19px;
            font-size: 14px;
        }

        @include xxs {
            font-size: 12px;
            padding: 14px;
        }

        &:after {
            content: '\f105';
            font-family: 'fontawesome';
            color: $red;
            position: absolute;
            top: 12px;
            right: 20px;
            font-size: 26px;
            transition: right .4s $smooth;

            @include md {
                top: 3px;
            }

            @include xs {
                top: 5px;
                right: 10px;
                font-size: 23px;
            }
        }

        &:last-child {
            border-right: 1px solid #dfd9d6;
        }

        &:hover,
        &.active {
            text-decoration: none;
            background: $pink;
            color: $red;

            &:after {
                right: 15px;
            }
        }
    }

    &.btn-horizontal {
        background: hsla(24, 20%, 90%, .8);
        color: $dark-grey;
        font-weight: 700;
        padding: 8px 15px;

        &:hover {
            color: $red;
            text-decoration: none;
            background: hsla(24, 20%, 90%, 1);
        }
    }

    &.btn-prev,
    &.btn-next {
        position: absolute;
        z-index: 1;
        top: 0;

        &:after {
             position: relative;
             z-index: 2;
             top: 5px;
             color: $red;
             font-family: 'fontawesome';
             font-size: 25px;
             font-weight: 700;
             line-height: 0;
        }

        &:hover {
            text-decoration: none;

            &:after {
                 color: $dark-grey;
            }
        }
    }

    &.btn-prev {
        &:after {
            content: '\f104';
        }
    }

    &.btn-next {
        &:after {
            content: '\f105';
        }
    } 
}
  
//fix bootstrap
a:not([href]):not([tabindex]) {
    color: $white;

    &:hover {
        color: $white;
    }
}
