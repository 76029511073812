@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Search Bar
--------------------------------------------------*/
.search-bar {
    border-bottom: 1px solid rgba(207, 197, 197, 0.4);
    padding-bottom: 80px;
    margin-bottom: 80px;

    @include sm {
        padding-bottom: 50px;
        margin-bottom: 50px;
    }

    .inline-search {
        width: 50%;
        margin: 0 auto;

        @include md {
            width: 80%;
            .form input {
                width: 88%;
            }
        }

        @include xs {
            width: 100%;

            .form input {
                width: 88%;
            }
        }
    }
}


//search bar big
.search-bar-big {

    .form {

        input {
            width: 90%;
            background: $white;
            display: inline-block;
            font-size: 25px;

            @include md {
                font-size: 20px;
            }

            @include notebook {
                font-size: 20px;
            }
        }

        button.btn {
            width: 5%;
            display: inline-block;
            margin-bottom: 0;
        }

        input::placeholder {
            color: $dark-grey;
            font-size: 25px;
            font-weight: 300;
            text-transform: none;

            @include md {
                font-size: 20px;
            }

            @include notebook {        
                font-size: 20px;
            }
        }
    }
}


//sticky-search
.sticky-search {

    input {
        width: 92%;
        background: transparent;
        display: inline-block;
        font-size: 16px;
        border-bottom: 0;

        @include md {
            font-size: 14px;
        }
    }

    button.btn {
        width: 5%;
        display: inline-block;
        margin-bottom: 0;
        
        &:after {
            color: $red;
            font-size: 20px;
        }
    }

     input::placeholder { 
        color: $dark-grey;
        font-size: 16px;
        font-weight: 500;
        text-transform: none;

        @include md {
            font-size: 14px;
        }
    }
}


//alphabetical list
.alphabetical-list {
    text-align: center;
    padding-top: 50px;
}


//mobile search
.mobile-search {
    position: fixed;
    z-index: 1;
    top: $header-height-compact;
    left: 0;
    width: 100vw;
    height: calc(100vh - #{$header-height-compact + $sticky-height-compact - 3});
    padding: 40px;
    @include alphabg($white, .95); 
    display: none;

    &.active {
        display: block;
    }
}