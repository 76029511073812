@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Form
--------------------------------------------------*/

.frm_error {
    color: $red;
    font-weight: 700;
}

.frm_hidden {
	display: none;
}

.form {

    .less-margin {
        margin-bottom: 10px !important;
    }

    .no-padding {
        padding-bottom: 0 !important;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px $white inset;
    }
    
    .obbl_fields {
        font-size: 14px;
        text-align: right;

        em {
            color: $red;
        }
    }

    .btn {
        margin-bottom: 0;
    }

    .form-group {
        display: block; 

        input,
        input[type="radio"] + label,
        input[type="checkbox"] + label:before,
        select option,
        select,
        textarea {
            width: 100%;
            padding: 16px;
            line-height: 1.4;
            background-color: #f9f9f9;
            border: 1px solid #e5e5e5;
            border-radius: 0;
            transition: 0.35s $smooth;
            transition: all 0.35s $smooth;
            cursor: pointer;
        }
         
        input:focus,
        textarea:focus,
        select:focus {
            outline: 0;
            border-color: $red;
        }

        input::placeholder,
        textarea::placeholder {
            color: $dark-grey;
            font-size: 16px;
            font-style: italic;
        }

        label {
            margin-bottom: 5px;
            font-weight: 700;
            text-transform: uppercase;

            em {
                color: $red;
            }

            span {
                text-transform: none;
                font-weight: normal;
            }
        }

        //control select
        .control-select {
            position: relative;
            z-index: 1;

            &:after {
                content: '';
                position: absolute;
                top: 5%;
                right: 0px;
                background: #f9f9f9;
                width: 23px;
                height: 90%;
                pointer-events: none;
            }

            &:before {
                content: '\f107';
                font-family: 'fontawesome';
                font-size: 24px;
                position: absolute;
                z-index: 2;
                top: 8px;
                right: 20px;
                pointer-events: none;
            }
        }

        //fix firefox
        @-moz-document url-prefix() { 
          .control-select {
              
             &:after {
              top: 1%;                 
              right: 1px;
              width: 21px;
              height: 97%;
             }

             select {
               height: 55px;
             }
          }
        } 

        //control radio btn
        .control-radio-btn {

            &:after {
                @include clearfix;
            }

            input[type="radio"] {
                display: none;
            }

            input[type="radio"] + label {
                display: inline-block;
                width: 50%;
                text-align: center;
                float: left;
                border-radius: 0;
            }


            input[type="radio"] + label i {
                padding-right: 0.4em;
            }

            input[type="radio"]:checked + label,
            input:checked + label:before {
                background-color: $red;
                color: $white;
                border-color: $red;
            }
        }

        //control radio inline
        .control-radio-inline {

            input[type="radio"] {
                display: none;
            }
            
            input[type="radio"] + label {
                position: relative;
                display: block;
                padding-left: 1.6em;
                cursor: pointer;
                width: auto;
                padding: 0;
                background: transparent;
                border: 0;

                span {
                    padding-left: 27px;
                }
            }

            input[type="radio"] + label:before {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 1em;
                height: 1em;
                padding: 0;
                font-size: 1.1em;
                font-family: FontAwesome;
                content: '\f1db';
                color: $dark-grey;
            }

            input[type="radio"] + label:after {
                position: absolute;
                top: 0.62em;
                left: 0.4em;
                font-size: 0.56em;
                color: $red;
                opacity: 0;
                font-family: FontAwesome;
                content: "\f111";
            }

             input[type="radio"]:checked + label:after {
                opacity: 1;
            }
        }

        //control checkbox
        .control-checkbox {
            display: inline-block;
        }

        input[type="checkbox"] {
            display: none;
        }

        input[type="checkbox"] + label {
            position: relative;
            display: block;
            padding-left: 1.6em;
            cursor: pointer;
            text-transform: none;
            min-height: 15px;
        }

        input[type="checkbox"] + label:before {
            position: absolute;
            top: 0.2em;
            left: 0;
            display: block;
            width: 1em;
            height: 1em;
            padding: 0;
            content: '';
        }

        input[type="checkbox"] + label:after {
            position: absolute;
            top: 0.02em;
            left: 0.1em;
            font-size: 1.1em;
            color: $red;
            opacity: 0;
            font-family: FontAwesome;
            content: "\f00c";
        }

        input:checked + label:after {
            opacity: 1;
        }

        //control password
        .control-password {
            position: relative;
            z-index: 1;

            .show-hide-pass {
                position: absolute;
                z-index: 1;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;

                &:after {
                    content: '\f06e';
                    font-family: 'fontawesome';
                    font-size: 18px;
                }

                &.show {
                    &:after {
                        content: '\f070';
                    }
                }
            }
        }
    }

    //form thin
    &.form-thin {

        input,
        select {
            background: transparent;
            border: 0;
            border-bottom: 1px solid #cfc5c5;
            font-size: 14px;
            padding: 10px 0 10px 0;
        }

        textarea {
            border: 1px solid #cfc5c5;
            font-size: 14px;
            background: transparent;
        }

        input::placeholder,
        textarea::placeholder,
        select {
            color: $red;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            font-style: normal;
        }

        .control-select {

             &:before {
                top: 2px;
                right: 5px;
             }

             &:after {
                background: $white;
            }
        }

        .control-password {
            .show-hide-pass {
                right: 0;
            }
        }

        .lost-psw {
            font-size: 14px;
        }
    }

    //form tiny
    &.form-tiny {

         input,
         select,
         text-area {
             font-size: 14px;
             padding: 6px 8px;
         }

         input::placeholder,
         textarea::placeholder  {
             font-size: 14px;
         }

         .control-select {
            
             &:before {
                 font-size: 20px;
                z-index: 2;
                top: 1px;
                right: 10px;
             }
         }

         .control-label {
              font-size: 14px;
         }

         .radio-inline {
             span {
                 font-size: 14px;
             }
         }

         input[type="checkbox"] + label {
             font-size: 14px;
        }
    }

    //form columns
    .form-row {
        zoom: 1;
        padding-bottom: 35px;

        @include xs {
            padding-bottom: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &:before,
        &:after {
            content: '';
            display: table;
        }

        &:after {
            clear: both;
        }

        @include xs {
            .form-group {
                padding-bottom: 20px;
            }

            &:last-child {
                .form-group {
                    padding-bottom: 0;
                }
            }
        }
    }

    .form-col-half {
        padding-right: 10px;
        float: left;
        width: 50%;

        &:last-of-type {
            padding-right: 0;
        }

        @include xs {
            width: 100%;
            padding-right: 0;
        }
    }

    .form-col-third {
        padding-right: 10px;
        float: left;
        width: 33.33333333%;

        &:last-of-type {
            padding-right: 0;
        }

        @include xs {
            width: 100%;
            padding-right: 0;
        }
    }
}


//sticky-search
.sticky-search,
.inline-search {

    .form {

        input {
            width: 92%;
            background: transparent;
            display: inline-block;
            font-size: 16px;
            border-bottom: 0;
            padding: 0;
            line-height: normal;
            transition: none;

            @include md {
                font-size: 14px;
            }
        }

        button.btn {
            width: 5%;
            display: inline-block;
            margin-bottom: 0;

            &:after {
                color: $red;
                font-size: 20px;
            }
        }

        input::placeholder {
            color: $dark-grey;
            font-size: 16px;
            font-weight: 500;
            text-transform: none;

            @include md {
                font-size: 14px;
            }
        }
    }
}

//inline-search
.inline-search {
    padding: 30px 0 0 0;

    button.btn {

        &:after {
            color: $dark-grey !important;
            font-size: 24px !important;
        }
    }
    
    input {
        border-bottom: 1px solid #cfc5c5 !important;
        padding-bottom: 5px !important;
    }

    input:focus,
    textarea:focus,
    select:focus {
        outline: 0  !important; 
        border-color: $red  !important;
    }

    input::placeholder { 
        font-size: 18px !important;
    }

    .form-group.search {
      position: relative;
      z-index: 1;

      input {
          width: 100%;
      }

      .btn.btn-search {
        position: absolute;
        z-index: 1;
        top: -3px;
        right: -8px;
        background: $white;

        @include xs {
            right: 0;
            top: -10px;
            width: auto;
        }

      }
    }
}

//datapicker
.datepicker-top-left, .datepicker-top-right {
    border-top-color: $red;

    &:before {
        border-bottom-color: $red;
    }
}

.datepicker-panel > ul > li.highlighted {
    background-color: $red;
    color: $white;
}

.datepicker-panel > ul > li:hover,
.datepicker-panel > ul > li.picked:hover {
    background-color: $light-grey;
    color: $red;
}

.log-error {
  padding-bottom: 12px;
  color: $red;
  font-weight: 600;
}


//formidable
.frm_forms  {

    .form-group {
        margin-bottom: 30px;
    }

    
    input[type="checkbox"] {
        display: inline-block !important;
        width: auto;
    }
}
    

#ui-datepicker-div {
    width: 210px !important;
    border: 1px solid #ccc;
    box-shadow: 0 3px 6px #ccc;
    box-sizing: content-box;
    position: absolute;
    z-index: 1;
    border-top: 1px solid $red;
    margin-top: 10px;

    
    &:before {
        border: 5px solid transparent;
        content: " ";
        display: block;
        height: 0;
        position: absolute;
        width: 0;
        border-top: 0;
        left: 10px;
        top: -5px;
        border-bottom-color: #b4111a;
    }

    &:after {
        border: 5px solid transparent;
        content: " ";
        display: block;
        height: 0;
        position: absolute;
        width: 0;
        border-bottom-color: #fff;
        top: -4px;
        border-top: 0;
        left: 10px;
    }

    a {
        font-size: 12px;
    }



    // reset table
    table {
      width: auto !important;
      height: auto !important;
      font-size: 12px;
      margin-bottom: 0;
      text-align: left;

      @include sm {
          border-collapse: collapse;
          overflow: auto;
          display: block;
          max-width: 100%;
          margin-bottom: 0;
          width: auto !important;
      } 

      tr {
          border: 0;
      }

      td {
          padding: 0;
          min-width: 0;
      }

      th {
          padding: 0;
          text-align: center;
      }

      tr:nth-child(2n) {
        background: none;
    
        td {
            border: 0;
        }
      }
    }

    table td {
        background-color: #fff;
        direction: ltr;
        font-size: 12px;
        left: 0;
        line-height: 30px;
        top: 0;
        touch-action: none;
        user-select: none;
        width: 210px;
        z-index: -1;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        text-align: center;
        color: $dark-grey;
        cursor: pointer;

        a {
            color: $dark-grey;
        }

        &:hover {
             background-color: #cccccc;
             
             a {
                 color: #b4111a;
             }
        }
    }

    .ui-datepicker-header {
        position: relative;
        z-index: 1;
        background: white;
        height: 30px;
    }

    .ui-datepicker-title {
        position: relative;
        z-index: 1;
        top: 3px;
        left: 50%;
        display: inline-block;
        transform: translateX(-50%);

        select {
            border: 0;
            -webkit-appearance: none;
        }
    }

    .ui-datepicker-calendar {
        background: white;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next  {
        color: $dark-grey !important;
        width: 30px;
        height: 30px;
        cursor: pointer;

        span {
            display: none;
        }

        &:hover {
            background-color: #cccccc;

                &:after {
                    color: #b4111a;
                }    
        }
    }

    .ui-datepicker-prev {
        position: absolute;
        z-index: 1;
        left: 0;

        &:after {
            font-family: 'fontawesome';
            content: '\f104';
            position: absolute;
            z-index: 1;
            font-size: 15px;
            top: 3px;
            left: 10px;
        }
    }

    .ui-datepicker-next {
        position: absolute;
        z-index: 1;
        right: 0;

        &:after {
            font-family: 'fontawesome';
            content: '\f105';
            position: absolute;
            z-index: 1;
            font-size: 15px;
            top: 3px;
            right: 10px;
        }
    }

}

