@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Features
--------------------------------------------------*/
.features {
    
    .features__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: calc(100% + 32px);
        height: 268px;
        margin: 0 -16px;
        background: #faf6f5;

        @include sm {
            height: auto;
            background: none;
        }
        
        .features__item {
            flex: 0 0 25%;
            max-width: 25%;
            padding: 0 16px;
            position: relative;
            z-index: 1;
            height: 100%;
            
            .icon,
            .text {
                transition: opacity .6s $smooth;
            }

            &:hover {
                text-decoration: none;
                
                .icon,
                .text {
                    opacity: 0.8;
                }
            }

            a {
                color: $dark-grey;
                text-decoration: none;
            }

            @include sm {
                flex: 0 0 50%;
                max-width: 50%;
                height: 150px;
                background: #faf6f5;
            }

            @include xs {
                height: 100px;
            }


            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                height: 174px;
                width: 4px;
                background: #e4e0df;

                @include sm {
                    display: none;
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            .box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;

                .icon {
                    margin-bottom: 16px;

                    img {
                        width: 70px;
                        height: 70px;
                        
                        @include md {
                            width: 50px;
                            height: 50px;
                        }

                        @include xs {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }

                .text {
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 22px;
                    max-width: 200px;
                    text-align: center;
                    line-height: 1.2;

                    @include md {
                        font-size: 16px;
                    }

                    @include xs {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

