
//--------------------------------------------------
//Variables
//--------------------------------------------------

//colors
$dark-grey:      #333333;
$light-grey:     #cccccc;  
$red:            #b4111a; 
$white:          #ffffff;
$pink:           #f9f6f4;
$blue:           #001b54;
$shadow:         0px 5px 22px -3px #d0b5a4;

//animations
$expo:                   cubic-bezier(1.000, 0.000, 0.000, 1.000); 
$smooth:                 cubic-bezier(.49, .06, .21, .98);

//various
$header-height:          130px;
$header-height-compact:  75px;
$search-panel-height:    150px;
$search-panel-height-sm: 75px; 
$sticky-height:          60px;
$sticky-height-compact:  40px;
