@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Embed
--------------------------------------------------*/

//embed
.embed {
    position: relative;
    z-index: 1;
    padding: 0 0 50px 0;

    @include md {
        padding: 0 0 30px 0;
    }

    .wrap {
        position: relative;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        padding-bottom: 10px;
        text-align: center;

        @include xs {
            padding-bottom: 40%;
        }

        @include xs {
            padding-bottom: 75%;
        }

        iframe {
            // position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
    }
} 