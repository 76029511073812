@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Data
--------------------------------------------------*/

table {
  width: 100% !important;
  height: auto !important;
  font-size: 14px;
  margin-bottom: 40px;
  text-align: left;

  @include sm {
      border-collapse: collapse;
      overflow-x: auto;
      display: block;
      max-width: 100%;
      margin-bottom: 40px;
      width: fit-content !important;
  } 

  tr {
      border-left: rgba(204, 204, 204, 0.2);
      border-right: rgba(204, 204, 204, 0.2);
  }

  td {
      padding: .5em 1em;
      min-width: 150px;
  }

  th {
      padding: .5em 1em;
  }

  tr:nth-child(2n) {
    background: rgba(204, 204, 204, 0.2);
    
    td {
       border-left: 1px solid $white;
       border-right: 1px solid $white;
    }
  }
}


.table {
    margin-bottom: 90px;

    tr {
        border-bottom: 1px solid $light-grey;
    }

    td {
        padding: 20px 0 0 0;
        min-width: auto;
    }

    th {
        padding: 0 0 10px 0;
    }

    .btn {
        margin-bottom: 0;
    }

    td.td-space {
        border-bottom: 1px solid transparent;
        width: 30px;
    }
     
    td.td-medium {
        width: 160px;
        vertical-align: bottom;  
    }

    &.table-lapse { 
        font-size: 16px;

        @include sm {
            font-size: 14px;
        }
    }

  tr:nth-child(2n) {
    background: transparent;
    padding: 0;

    td {
       border-left: 0;
       border-right: 0;
    }
  }

  .col-biggest {
    width: 75%;

    @include sm {
      width: 100%;
    }
  }

  .col-half {
    width: 50%;

    @include sm {
      width: 100%;
    }
  }

  .col-quarter {
    width: 25%;

    @include sm {
      width: 100%;
    }
  }

  .col-smaller {
    width: 15%;

    @include sm {
      width: 100%;
    }
  }
} 


@include sm {
  .table {
    border: 0;
     width: 100% !important;
  }

  .table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 30px;
  }

  .table td {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
    padding: .5em 0;
    min-width: 150px;
  }

  .table th {
    display: none;
  }

  .table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .table td:last-child {
    border-bottom: 0;
  }

  .table td.td-space {
      display: none;
  }

  .table td.td-medium {
        width: auto;
  }

  .table-lapse {

      td {
          text-align: left;
          padding-top: 0px;
          padding-bottom: 10px;

          &.td-medium {
              font-weight: 700;
              border-bottom: 0;

          }
      }
  }
}


//loading
.loading {
    display: none;
    position: fixed;
    z-index: 99995;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.active {
        display: block;
    }

    span {
        border: 6px solid rgba(100, 100, 100, 0.4);
        border-top: 6px solid rgba(255, 255, 255, 0.6);
        border-radius: 100%;
        height: 50px;
        width: 50px;
        animation: fancybox-rotate .8s infinite linear;
        background: transparent; 
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -30px;
        margin-left: -30px;
        z-index: 2;      
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #1e1e1e;
        opacity: 0.87;
        transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
        transition-duration: inherit;
        transition-property: opacity;
    }
}

//pagination
.pagination {
    text-align: center;
    padding-bottom: 80px;
    position: relative;
    z-index: 1;

    .btn.btn-prev,
    .btn.btn-next {
        position: absolute;
        z-index: 1;
        top: 2px;
        margin-bottom: 0;

        @include sm {
             top: -1px;
        }
    }

    .btn.btn-prev {
        left: 0;
    }

    .btn.btn-next {
        right: 0;
    } 

    @include xxs {
        ul li {

            &:nth-child(n+4):nth-child(-n+9) {
                display: none;
            }

            &:nth-child(3) {
                position: relative;
                z-index: 1;
                padding-right: 20px;

                &:after {
                    content: '...';
                    position: absolute;
                    z-index: 1;
                    top: 6px;
                    right: 2px;
                }
            }
        }
    }
}

ul.horizontal {
    margin-bottom: 0;
    padding: 0 15px;
      
    li {
        display: inline-block;

        a {
            padding: 4px 4px;
            width: 32px;
            display: block;
            text-align: center;
            color: $dark-grey;

            @include sm {
                padding: 2px 4px;
                width: 22px;
                font-size: 12px;
            }

            &:hover {
                color: $white;
                text-decoration: none;
                @include alphabg($red, .8);
            }

            &.active {
                color: $white;
                background: $red;
            }
        }
    }
}