@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Downloads
--------------------------------------------------*/
.downloads {
    @include alphabg($pink, .3);
    padding: 90px 0;

    @include md {
        padding: 40px 0;
    }

    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -15px;
        
        @include xxs {
            display: block;
        }

        li {
            flex: 0 0 33.333%;
            padding: 0 15px;
            margin-bottom: 15px;

            @include sm {
                flex: 0 0 50%;
            }

            a {
                font-weight: 700;
                text-decoration: underline;
                
                i {
                    color: $dark-grey;
                    text-decoration: none;
                    margin-right: 5px;
                }

                 &:hover {
                    text-decoration: none;
                    color: $dark-grey;
                }
            }
        }
    }
}