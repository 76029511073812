@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Base
--------------------------------------------------*/

//container
body {
    background: #f5f5f5;
    min-height: 100vh;
}

.wrapper {
    max-width: 1920px;
    background: #fff;
    margin: 0 auto;
    min-height: 100vh;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    overflow: hidden; 
    padding-top: 130px;

    @include sm {
        padding-top: 75px;
    }
}

.big-container {
    width: 90%;
    margin: 0 auto;
}

.mid-container {
    width: 80%;
    margin: 0 auto;
}

.small-container {
    width: 66.666%;
    margin: 0 auto;

    @include sm {
        width: 90%;
    }
}

.smallest-container {
    width: 50%;
    margin: 0 auto;

    @include md {
        width: 66.666%;
    }


    @include sm {
        width: 90%;
    }
}

.sidebar-container {
    margin: 0 auto;
    width: 90%;
}

//elements
*:focus {
    outline: none!important;
}

img {
    max-width: 100%;
}

p {
   margin-bottom: 20px;
}

a {
    color: $red;

    &:active,
    &:focus {
        @include alphatxt($red, .8);
        text-decoration: none;
    }

    &:hover {
        @include alphatxt($red, .8);
    }
}

ul {
    padding-left: 18px;
    margin-bottom: 37px;

    &.no-style {
        list-style: none;
        padding-left: 0;
    }

    &.inline {

        li {
            display: inline-block;
            padding: 0 10px 0 0;
        }
    }

    li {
        padding-bottom: 5px;

        @include sm {
            padding-bottom: 10px;
        }
    }
}

.text {
    margin-bottom: 37px;

    @include sm {
        margin-bottom: 20px;
    }
}

.img {

    &.img-cover {
        position: relative;
        z-index: 1;
        display: block;
        height: 0;
        padding-bottom: 75%;
        overflow: hidden;
        margin-bottom: 37px;

        svg {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.img-border {
        border: 1px solid #efe3da;
    }
}

.ornament {
    background: $dark-grey;
    width: 40px;
    height: 4px;
    margin-bottom: 37px;

     @include sm {
        margin-bottom: 20px;
    }

    &.align-center {
        margin: 0 auto 37px auto;

         @include sm {
            margin-bottom: 20px;
        }
    }
}

.eyelet {
    font-size: 14px;

    @include sm {
        font-size: 12px;
    }
}


//inline shortcuts
.align-left {
    text-align: left;

    .text, 
    .page-content {
      text-align: left;
    }
} 

.align-center {
    text-align: center;

    .text, 
    .page-content {
      text-align: center;
    }
} 

.align-right {
    text-align: right;

    .text, 
    .page-content {
      text-align: right;
    }
} 

.uppercase {
    text-transform: uppercase;
}

.no-margin {
  margin: 0;
}

.red {
  color: $red;
}

//window boxed
.window-boxed {
    margin: 0 auto;
    width: 70%;
    padding: 50px 60px;
    box-shadow: 0px 5px 22px -3px #d0b5a4;

    @include md {
        width: 100%;
    }

    @include sm {
        padding: 20px 30px;
    }
}


//window smallest
.window-smallest {
    margin: 0 auto;
    width: 40%;
    padding: 40px 50px;
    box-shadow: 0px 5px 22px -3px #d0b5a4;

    @include xl {
        width: 70%;
    }

    @include md {
        width: 80%;
    }

    @include xs {
        width: 100%;
        padding: 20px 30px;
    }
}


