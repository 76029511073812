@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Autocomplete
--------------------------------------------------*/
.sticky-autocomplete {
    position: absolute;
    bottom: 43px;
    left: 0;
    width: 100%;
    max-height: 40vh;
    z-index: 1;
    background: $pink;
    box-shadow: 1px 1px 22px 0px #d0b5a4;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid $red;
    padding: 30px;

    @include md { 
        bottom: 32px;
        width: 100%;
    }

    .wrap {

        .box {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .h5 {
                font-size: 16px;
                padding-bottom: 10px;
                margin-bottom: 0;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    padding-bottom: 5px;
                    
                    &:last-child {
                        padding-bottom: 0;
                    }

                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &.search-panel-autocomplete {
      display: block;
      bottom: 133px;
      left: -30px;
      background: $white;
      padding: 30px 130px 30px 30px;
      box-shadow: 0px -12px 22px 0px #d0b5a461;

      @include md {
        padding: 30px 30px 30px 30px;
        bottom: 110px;
      }
    }
}