@import 'variables';
@import 'mixins';

/*--------------------------------------------------
More
--------------------------------------------------*/

//more
.more {
    padding: 70px 0;

    @include md {
        padding: 40px 0;
    }
}
