@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Cover
--------------------------------------------------*/
.forza_immagine {
	background-size: contain !important;
	background-repeat: no-repeat;
}

.cover {
    background: $pink;
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
    box-shadow: $shadow;

    @include sm {
        margin-bottom: 20px;
    }

    .big-container {
        display: flex;
        flex-direction: row;

        @include xs {
            display: block;
        }
    }

    .text {
        margin-bottom: 0;
        font-size: 24px;

        @include sm {
            font-size: 18px;
        }

        a {
            font-weight: 700;
        }

        p {
            margin-bottom: 0;
        }
    }

    .eyelet {
      margin-top: 25px;
      font-weight: 700;
    }
   
    .title {
        flex: 0 0 66.666%;
        max-width: 66.666%;

        @include sm {
            max-width: none;
            min-height: auto;
        }

        .box {
					padding: 37px 37px 37px 0;
			}
    }

    .item {
        flex: 0 0 40%;
        max-width: 40%;
        background-size: cover;
        background-position: center center;

        &.no-image {
          min-height: 0;
        }

        @include xs {
            height: 0;
            max-width: none;
            width: calc(100% + 10%);
            padding-bottom: 35%;
            margin: 0 -5%;
        }

        &.static {
            background-size: auto;
            text-align: center;
            align-self: flex-end;

            @include xs {
                height: auto;
                width: auto;
                padding-bottom: 0;
                margin: auto;
            }
        }
    }

    &:after {
        @include clearfix;
    }
}

.cover--small {

    .title {
        min-height: none;
    }
}

.cover-search {
    height: calc(100vh - 130px);
    background-size: cover;
    background-position: center center;
    margin-bottom: 120px;

    @include sm {
        min-height: 700px;
        height: calc(100vh - 71px);
        display: flex;
        align-items: center;
        margin-bottom: 48px;
    }

    .cover-search__wrapper {
        height: 100%;
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include sm {
            display: block;
            height: auto;
            width: 90%;
        }
    }

    .cover-search__content {
        color: $white;
        flex: 0 0 60%;
        max-width: 60%;
        padding: 0 40px;

        @include lg {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include sm {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
            padding: 0;
            margin-bottom: 40px;
        }

        .box {

            .h1 {
                font-size: 49px;
                font-weight: 500;

                @include sm {
                    font-size: 28px;
                    text-align: center;
                    line-height: 1.2;
                }
            }

            .text {
                font-size: 32px;
                line-height: 37px;
                text-align: left;
                
                @include sm {
                    font-size: 20px;
                    text-align: center;
                    line-height: 1.2;
                }
            }
        }
    }

    .cover-search__panel {
        flex: 0 0 40%;
        max-width: 40%;
        padding: 0 40px;

        @include lg {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include sm {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
        }
    }
    #autocomplete {
      width: 100%;
      max-height: 120px;
      padding: 0 10px;
      overflow-y: scroll;
      position: absolute;
      background: white;
      left: 0;
      top: 50px;
      .myhospopt {
        cursor: pointer;
        padding: 5px 0 5px 0;
        white-space: normal;
        border-bottom: 1px solid #dd0000;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
          background: rgba(0.3, 0.3, 0.3, 0.3);
        }
      }
    }
}

.find-panel {
    color: #333333;
    background: #F9F6F4;
    padding: 41px;
    border: 1px solid #333333;
    box-shadow: 0px 8px 48px 0px #00000094;

    @include sm {
        padding: 25px;
    }

    .find-panel__wrapper {
        font-weight: 700;
    }

    .form-find {

        .btn {
            width: 100%;
            display: inline-block;
            margin-bottom: 0;
            font-size: 22px;
            padding: 12px 28px; 
            text-align: center;
        }

        .form-group {
            margin-bottom: 40px;

            @include sm {
                margin-bottom: 20px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .radio-item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                position: absolute;
                left: -9999px;
            }

            [type="radio"]:checked+label,
            [type="radio"]:not(:checked)+label {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
                display: inline-block;
                color: #333333;
                line-height: 0.9;
                margin: 0;
            }

            [type="radio"]:checked+label:before,
            [type="radio"]:not(:checked)+label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 12px;
                height: 12px;
                border: 1px solid #333333;
                border-radius: 100%;
                background: #F9F6F4;
            }

            [type="radio"]:checked+label:after,
            [type="radio"]:not(:checked)+label:after {
                content: '';
                width: 8px;
                height: 8px;
                background: #333333;
                position: absolute;
                top: 2px;
                left: 2px;
                border-radius: 100%;
                transition: all 0.2s ease;
            }

            [type="radio"]:not(:checked)+label:after {
                opacity: 0;
                transform: scale(0);
            }

            [type="radio"]:checked+label:after {
                opacity: 1;
                transform: scale(1);
            }
        }

        .select-item {

            .select-control {
                position: relative;
                
                select {
                    padding: 16px 24px;
                    border-radius: 0;
                    border-color: #E8E8E8;
                    display: block;
                    width: 100%;
                    cursor: pointer;

                    &::placeholder {
                        color: #333333;
                        font-weight: 700;
                        font-size: 16px;
                    }

                    option { 
                        color: #333333;
                        border: 1px solid #000; 
                        background: #ffffff;
                        border-radius: 0;
                    }

                    
                    &::-ms-expand {
                        display: none;
                    }
                    
                }

                .select-arrow {
                    display: block;
                    position: absolute;
                    z-index: 0;
                    right: 0px;
                    top: 0px;
                    height: 100%;
                    width: 55px;
                    background-color: #B4101A;
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='white' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: center;
                    pointer-events: none;
                }

                select:focus + .select-arrow {
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 7L7 1L1 7' stroke='white' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                }

                select:focus {
                    box-shadow: 0px 8px 48px 0px #0000001a

                }
            }
        }

        .find-control {
            position: relative;

            input {
                padding: 16px 24px;
                border-radius: 0;
                display: block;
                width: 100%;
                cursor: pointer;
                border: 1px solid #E8E8E8;

                &::placeholder {
                    color: #333333;
                    font-weight: 400;
                    font-size: 14px;
                }

                &:focus {
                    box-shadow: 0px 8px 48px 0px #0000001a
                }
            }

            &:after {
                content: '';
                position: absolute;
                right: 18px;
                top: 18px;
                width: 22px;
                height: 22px;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.4121 14.4121L20 20' stroke='%23333333' stroke-width='2' stroke-linecap='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z' stroke='%23333333' stroke-width='2'/%3E%3C/svg%3E%0A");
            }
        }
    }
}