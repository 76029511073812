@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Main Content
--------------------------------------------------*/

.main-content {
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    
    //lateral wrap
    .lateral-wrap {
        width: 33.333%;
        margin: 0 -15px;

        @include md {
            width: 45%;
        }

        @include xs {
            width: 100%;
            margin: 0;
        }

        .highlights,
        .recent-news {
            padding: 0 15px;

            @include sm {
                padding: 0;
                
                .h1,
                .ornament {
                    margin-left: 15px;
                }

                .box {
                    .h1,
                    .ornament {
                        margin-left: 0;
                    }
                }
            }

            @include xs {
                .h1,
                .ornament {
                    margin-left: 0;
                }
            }

            .btn {
                margin-bottom: 0;
            }
        }

        .highlights {
            
            .box {
                margin-bottom: 150px;

                @include sm {
                    margin-bottom: 30px;
                    padding: 0 15px;
                }

                @include xs {
                    padding: 0;
                }
            }

            .img {
                
                @include sm {
                    padding-bottom: 60%;
                }

                @include xs {
                    padding-bottom: 50%;
                }
            }

            .no-thumb {
                margin-bottom: 40px;

                @include sm {
                    padding-bottom: 20px;
                }
            }
        }

        .recent-news {

            .box {
                 margin-bottom: 100px;

                 @include sm {
                    margin-bottom: 30px;
                    padding: 0 15px;
                }

                @include xs {
                    padding: 0;
                }
            }

            .cta.more-news {
                margin-left: 30%;
                margin-bottom: 150px;

                @include sm {
                    margin: 0 auto 60px auto;
                    text-align: center;
                }
            }
        }
    }

    //dashboard content
    .dashboard-content {
        margin-bottom: 100px;
    }

    //page content
    .page-content {
        margin-bottom: 90px;

        @include md {
            margin-bottom: 40px;
        }
    }

    //listing content
    .listing-content {
         margin-bottom: 90px;

        @include md {
            margin-bottom: 40px;
        }
    }

    //search results content
    .search-results-content {
        padding: 90px 0 70px 0;
        position: relative;
        z-index: 1;

        @include md {
            padding: 40px 0 20px 0;
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: -5vw;
            width: 100vw;
            height: 1px;
            background: #dfd9d6;
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &.search-medic-content {

          .box {
            .eyelet {
              font-weight: 600 !important;
              padding-bottom: 3px;
            }

            .text {
              margin: 10px 0 20px 0 !important;
            }
          }

          p {
            margin-bottom: 0;
          }
        }
    }

    //faq content
    .faq-content {
       
        .h3 {
            margin-bottom: 5px;
        }

        .faq-wrap {
            padding-bottom: 60px;
        }

        .box + .faq-wrap {
            padding-top: 40px;
        }

        @include xs {

            .faq-wrap {
                padding-bottom: 0px;
            }

            .box + .faq-wrap {
                padding-top: 20px;
            }

            .faq-title {
                position: relative;
                z-index: 1;
                cursor: pointer;

                .h4 {
                    background: $pink;
                    padding: 10px 20px;
                }
            }

            .faq-wrap {
                .accordion {
                    padding-left: 20px;
                }
            }
        }
    }

    //faq sidebar content
    .faq-sidebar-content {
        background: $pink;
        padding: 40px;
        position: relative;
        z-index: 1;
        box-shadow: $shadow;
        width: calc(100% + 5vw);
        margin-left: -5vw;
        padding-left: 5vw;

        @include xl {
            padding: 10px 10px 10px 0;
            padding-left: 5vw;
        }

        ul {
            margin-bottom: 0;

            li {
                font-weight: 700;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

    }

    //alphabetic content
    .alphabetic-content {
        
        .box {
            padding-bottom: 30px;

            .h3 {
                margin-bottom: 5px;
            }

            .text {
                font-size: 20px;
            }
        }

        .alphabetic-list {

             ul {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;

                @include xxs {
                    display: block;
                }

                li {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding: 0;
                    display: block;
                    text-transform: none;
                    text-align: left;
                    margin-bottom: 50px;

                    @include xxs {
                         max-width: 100%;
                         margin-bottom: 20px;
                    }
                }
            }
        }
    }

    //text content
    .text-content,
    .medic-content {
        
        .box {
            padding-bottom: 50px;

            @include md {
                padding-bottom: 30px;
            }
        }
    }

    //disease content
    .disease-content {

        padding-bottom: 50px;

        @include md {
            padding-bottom: 30px;
        }
    }

    
    //styleguide
    .styleguide {
        margin: 80px 0 200px 0;

        .styleguide-wrap {
            margin-bottom: 120px;
        }
    }

    &:after {
        @include clearfix;
    }
}

.search-panel + .main-content {
     margin-top: 60px;

     @include xs {
        margin-top: 20px;
     }
}

