@import 'variables';
@import 'mixins';

/*--------------------------------------------------
Payoff
--------------------------------------------------*/
.payoff {
    position: relative;
    z-index: 1;
    margin-bottom: 160px;

    @include md {
        margin-bottom: 80px;
    }

    a {
        color: $white;
    }

     &.less-margin {
        margin-bottom: 90px;

        @include md {
            margin-bottom: 60px;
        }
    }

    .box {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 500px;

        @include xs {
            width: 90%;
        }
    }

    .btn {
        margin-bottom: 0;
    }

    .img {
        position: relative;
        z-index: 1;
        display: block;
        height: 0;
        padding-bottom: 30%;
        overflow: hidden;

        @include md {
            padding-bottom: 40%;
        }



        @include xxs {
            padding-bottom: 75%;
        }

        svg {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include alphabg($dark-grey, .3);
        }
    }

    //da tablet in su
    @media (min-width: 990px) {
        a {
            
            .img svg {
                transition: transform 1s $smooth;
            } 

            .img:after {
                transition: background 1s $smooth;
            }

            &:hover { 
                
                .img svg {
                    transform: scale(1.02);
                }

                .img:after {
                    @include alphabg($dark-grey, .5);
                }
            }
        }
    }
}